import * as React from 'react';
import {Task} from "../../models/task";
import {Level} from "../../models/level";
import {TaskListComponent} from "../../components/TaskList/TaskListComponent";
import {connect} from "react-redux";
import {match} from "react-router";
import {History} from 'history';
import {IonContent} from "@ionic/react";
import {Settings} from "../../models/settings";
import {appStorage} from "../../services/AppStorage";
import CrossGestureHandler from "../../components/CrossGestureHandler/CrossGestureHandler";

type TasksListScreenProps = {
    match: match<{ level_id: string }>,
    history: History,
    levels: Level[],
    tasks: Task[],
};

interface TasksListPropsState {
    settings: Settings;
}

class TasksListScreen extends React.Component<TasksListScreenProps, TasksListPropsState> {

    constructor(props: TasksListScreenProps) {
        super(props);
        this.state = {
            settings: {} as Settings,
        };
        appStorage.getSettings().then(settings => {
            this.setState(prev => ({...prev, settings}));
        })
    }

    componentDidMount() {

        const {history, match, tasks} = this.props;

        if ((match.params as any)['operation'] == 'next-task') {
            const currentLevelTasks = [...tasks]
                .filter(t => t.level_id == Number(match.params.level_id))
                .sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

            const getFirstUndone = () =>
                currentLevelTasks.find(t => !t.done);

            const getFirst = () =>
                currentLevelTasks.shift();

            const nextTask = getFirstUndone() || getFirst();

            if (nextTask) {
                history.push(`/tasks/${nextTask!.id}`);
            }

            return null;
        }
    }

    render() {
        const {levels, history, match, tasks} = this.props;
        const goBack = () => {
            history.push('/levels');
        }

        const level_id = Number(match.params.level_id);
        const level = levels.find(level => level.id == level_id);

        const onClick = (task: Task) => history.push(`/tasks/${task.id}`);

        const completedTasksCount = () => tasks.filter(t => t.done).length;

        const renderTasks = () => {
            const filteredTasks = tasks
                .filter(task => task.level_id == level?.id && task.word.trim().length > 0)
                .sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)
            return (
                <TaskListComponent
                    tasks={filteredTasks}
                    onClick={(task: Task) => onClick(task)}
                    goBack={() => goBack()}
                    completedTasksCount={completedTasksCount()}
                />
            );
        }

        return (
            <IonContent>
                {renderTasks()}

                <CrossGestureHandler
                    onGoToParentMode={() => this.goToParentMode()}
                    onSetParentPassword={(password) => this.setNewPassword(password)}
                    settings={this.state.settings}/>
            </IonContent>
        );
    }

    private goToParentMode() {
        this.props.history.replace('/parent/levels');
    }

    private setNewPassword(password: string) {
        appStorage.setParentPassword(password);
    }
}

const mapStateToProps = (state: any) => {
    const {levels, tasks} = state
    return {levels: levels.levels, tasks: tasks.tasks};
};
export default connect(mapStateToProps)(TasksListScreen);
