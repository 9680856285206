import * as React from 'react';
import {CSSProperties} from 'react';
import {IonContent} from "@ionic/react";
import {BasePageProps} from "../../types";
import {CardCompletionSetting, Settings} from "../../models/settings";
import {appStorage} from "../../services/AppStorage";
import {PluginListenerHandle, Plugins} from "@capacitor/core";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {dropProgress, removeLevel, updateLevel} from "../../state/levels/actions";
import {addTask, dropAllProgress, updateTask} from "../../state/tasks/actions";

const {Keyboard} = Plugins;


interface SettingsScreenProps extends BasePageProps<any> {
    dropAllProgress: Function;
}

interface SettingsScreenState {
    settings: Settings;
    passwordModalVisible: boolean;
    dropProgressModalVisible: boolean;
    factoryResetModalVisible: boolean;
    keyboardHeight: number;
    passwordInput: string,
    passwordRepeatInput: string,
    modalError: string,
}

class SettingsScreen extends React.Component<SettingsScreenProps, SettingsScreenState> {

    constructor(props: SettingsScreenProps) {
        super(props);
        this.state = {
            settings: new Settings(),
            passwordModalVisible: false,
            dropProgressModalVisible: false,
            factoryResetModalVisible: false,
            keyboardHeight: 0,
            passwordInput: '',
            passwordRepeatInput: '',
            modalError: '',
        };
        appStorage.getSettings().then(settings => {
            this.setState(prev => ({...prev, settings}));
        });
    }

    componentDidMount() {
        this.keyboardListeners.push(Keyboard.addListener("keyboardWillShow", (e) => {
            this.setState(prev => ({...prev, keyboardHeight: e.keyboardHeight}));
        }));
        this.keyboardListeners.push(Keyboard.addListener("keyboardDidHide", () => {
            this.setState(prev => ({...prev, keyboardHeight: 0}));
        }));
    }

    componentWillUnmount() {
        for (let listener of this.keyboardListeners) {
            if (typeof listener.remove === 'function') {
                listener.remove();
            }
        }
    }

    private keyboardListeners: PluginListenerHandle[] = [];

    private goBack() {
        this.props.history.replace('/parent/levels');
    }

    private setCardCompletion(cardCompletion: CardCompletionSetting) {
        appStorage.updateSettings({cardCompletion});
        this.setState(prev => ({...prev, settings: {...this.state.settings, cardCompletion}}));
    }

    private toggleAskPassword() {
        appStorage.getSettings().then(settings => {
            const newSettings = {...settings, askPassword: !settings.askPassword};
            appStorage.updateSettings(newSettings)
            this.setState(prev => ({...prev, settings: newSettings}));
        });
    }

    renderPasswordModal() {
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!this.state.passwordModalVisible) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        let modalStyle: CSSProperties = {};

        if (this.state.keyboardHeight) {
            modalStyle.top = `calc(50% - ${this.state.keyboardHeight / 2}px)`;
        }


        const renderModalContent = () => {
            return (
                <div className={modalClass} style={modalStyle}>
                    <div className="modal-title">Задайте родительский пароль</div>
                    <div className="form form-modal form-modal2">
                        <label>
                            <input type="text"
                                   placeholder="Пароль"
                                   onChange={evt => this.setPasswordInput((evt.target as any).value)}
                                   value={this.state.passwordInput}/>
                        </label>
                        <label>
                            <input type="text"
                                   placeholder="Повтор пароля"
                                   onChange={evt => this.setPasswordRepeatInput((evt.target as any).value)}
                                   value={this.state.passwordRepeatInput}/>
                        </label>
                        {this.state.modalError ? <div className="error">{this.state.modalError}</div> : null}
                        <button type="submit" className="btn btn-accent" onClick={() => this.setNewPassword()}>
                            <span>Задать</span>
                        </button>
                    </div>
                </div>
            );
        };

        return (

            <div className={this.state.passwordModalVisible ? '' : 'hidden'}>
                <div className={overlayClass} onClick={() => this.dismissPasswordModal()}/>
                {renderModalContent()}
            </div>
        );
    }

    renderDropProgressModal() {
        let dropProgressModalVisible = this.state.dropProgressModalVisible;
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!dropProgressModalVisible) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        return (
            <div className={dropProgressModalVisible ? '' : 'hidden'}
                 onClick={() => this.dismissDropProgressModal()}>
                <div className={overlayClass}/>
                <div className={modalClass}>
                    <div className="modal-title">Сброс прогресса</div>
                    <div className="box-text">
                        <p>При сбросе прогресса все результаты прохождения будут безвозвратно удалены.</p>
                        <p>Вы уверены, что хотите удалить прогресс?</p>
                    </div>

                    <div className="button-group button-group-row">
                        <a className="btn btn-accent" onClick={() => {
                            this.dropProgress();
                            this.dismissDropProgressModal();
                        }}>
                            <span>Да</span>
                        </a>
                        <a className="btn btn-accent" onClick={() => this.dismissDropProgressModal()}>
                            <span>Нет</span>
                        </a>
                    </div>

                </div>
            </div>
        );
    }


    renderFactoryResetModal() {
        let factoryResetModalVisible = this.state.factoryResetModalVisible;
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!factoryResetModalVisible) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        return (
            <div className={factoryResetModalVisible ? '' : 'hidden'}
                 onClick={() => this.dismissFactoryResetModal()}>
                <div className={overlayClass}/>
                <div className={modalClass}>
                    <div className="modal-title">Восстановление настроек</div>
                    <div className="box-text">
                        <p>При восстановлении настроек приложение откатится в изначальный вид. Все результаты
                            прохождения и
                            пользовательские уровни будут безвозвратно удалены.</p>

                        <p>Вы уверены, что хотите восстановить настройки?</p>
                    </div>

                    <div className="button-group button-group-row">
                        <a className="btn btn-accent" onClick={() => {
                            this.factoryReset();
                            this.dismissFactoryResetModal();
                        }}>
                            <span>Да</span>
                        </a>
                        <a className="btn btn-accent" onClick={() => this.dismissFactoryResetModal()}>
                            <span>Нет</span>
                        </a>
                    </div>

                </div>
            </div>
        );
    }


    render() {
        return (
            <IonContent className="parent">
                <div className="wrapper">
                    <div className="header">
                        <div className="container">
                            <a className="btn-back" onClick={() => this.goBack()}>
                            <span>
                                <img src="/assets/images/arrow-left.svg" alt=""/>
                            </span>
                            </a>
                            <div className="header-title">Настройки</div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container">
                            <div className="form form-password">
                                <div className="popup-box">
                                    <div className="popup-box-title">прохождение карточек</div>
                                    <label className="radio">
                                        <input className="radio-inp"
                                               type="radio"
                                               name="radio"
                                               checked={this.state.settings.cardCompletion == 'byOrder'}
                                               onChange={() => this.setCardCompletion('byOrder')}
                                        />
                                        <span className="radio-custom"/>
                                        <span className="label">По порядку</span>
                                    </label>
                                    <label className="radio">
                                        <input className="radio-inp"
                                               type="radio"
                                               name="radio"
                                               checked={this.state.settings.cardCompletion == 'notCompleted'}
                                               onChange={() => this.setCardCompletion('notCompleted')}
                                        />
                                        <span className="radio-custom"/>
                                        <span className="label">Только нерешенные</span>
                                    </label>
                                </div>

                                <div className="popup-box">
                                    <div className="popup-box-title">пароль</div>
                                    <label className="checkbox">
                                        <input className="checkbox-inp"
                                               type="checkbox"
                                               name="checkbox"
                                               onChange={() => this.toggleAskPassword()}
                                               checked={this.state.settings.askPassword}
                                        />
                                        <span className="checkbox-custom"/>
                                        <span className="label">
                                        Запрашивать пароль при
                                        <br/>
                                        входе в родительский режим
                                    </span>
                                    </label>
                                    <button type="submit" className="btn btn-accent"
                                            onClick={() => this.showPasswordModal()}>
                                        <span>задать</span>
                                    </button>
                                </div>
                            </div>
                            <div className="button-group">
                                <a className="btn btn-border" onClick={() => this.showDropProgressModal()}>
                                    <span>Сбросить прогресс</span>
                                </a>
                                <a className="btn btn-border" onClick={() => this.showFactoryResetModal()}>
                                    <span>Восстановить настройки по умолчанию</span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                {this.renderPasswordModal()}
                {this.renderDropProgressModal()}
                {this.renderFactoryResetModal()}
            </IonContent>
        );
    }

    private showPasswordModal() {
        this.setState(prev => ({...prev, passwordModalVisible: true}));
    }

    private dismissPasswordModal() {
        this.setState(prev => ({
            ...prev,
            passwordModalVisible: false,
            passwordInput: '',
            passwordRepeatInput: '',
            modalError: '',
        }));
    }

    private showDropProgressModal() {
        this.setState(prev => ({...prev, dropProgressModalVisible: true}));
    }

    private dismissDropProgressModal() {
        this.setState(prev => ({
            ...prev,
            dropProgressModalVisible: false,
        }));
    }

    private showFactoryResetModal() {
        this.setState(prev => ({...prev, factoryResetModalVisible: true}));
    }

    private dismissFactoryResetModal() {
        this.setState(prev => ({
            ...prev,
            factoryResetModalVisible: false,
        }));
    }

    private setPasswordInput(passwordInput: string) {
        this.setState(prev => ({
            ...prev,
            passwordInput,
        }));
    }

    private setPasswordRepeatInput(passwordRepeatInput: string) {
        this.setState(prev => ({
            ...prev,
            passwordRepeatInput,
        }));
    }

    private setNewPassword() {
        if (this.state.passwordInput != this.state.passwordRepeatInput) {
            this.setState(prev => ({...prev, modalError: 'Пароли не совпадают'}));
            return;
        }

        appStorage.setParentPassword(this.state.passwordInput);
        this.dismissPasswordModal();
    }

    private dropProgress() {
        this.props.dropAllProgress();
        this.dismissDropProgressModal();
    }

    private factoryReset() {
        this.props.dropAllProgress();
        appStorage.clearSettings();
        this.dismissFactoryResetModal();
        this.setState(prev => ({...prev, settings: new Settings()}));
    }
}

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        dropAllProgress,
    }, dispatch)
);
const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
}
export default connect(null, mapDispatchToProps)(SettingsScreen);
