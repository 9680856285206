export type LevelRewardType = 'bubbles'|'youtube';

export class Level {
    id?: number;
    level_number: number;
    reward_type: LevelRewardType = Level.REWARD_BUBBLES;
    reward_youtube_url: string = '';
    reward_bubble_type: number = 1;
    reward_bubble_sound_type: string = '';
    reward_youtube_duration_secs?: number;
    completed_tasks_required: number = 0;
    local_video_path: string = '';

    static readonly REWARD_BUBBLES = 'bubbles';
    static readonly REWARD_YOUTUBE = 'youtube';

    constructor(props: Partial<Level>) {
        const taskKeys = Object.keys(props);
        for (let key of taskKeys) {
            // @ts-ignore
            if (props[key] !== undefined) {
                // @ts-ignore
                this[key] = props[key];
            }
        }
        if (typeof this['level_number'] == 'undefined') {
            throw new Error('level number is not defined while instantiating Level class');
        }
    }

    static fromJson(level: Level) {
        return new Level(level);
    }
}

