import {BACKEND_URL} from "../constants";
import {calculateCharactersToChoose} from "../components/Game/charactersToChoseCalculate";
import {CharacterToChoose} from "../components/Game/types";
import {normalizeSrc} from "../App";

export type TaskType = 'same_char' | 'char' | 'word';

export class Task {
    id?: number;
    type: TaskType = Task.TYPE_FIND_SAME_CHAR;
    difficulty: number = 1;
    word: string = '';
    img_path: string = '';
    img_external_path?: string;
    sort_order: number = 1;
    // @ts-ignore
    level_id: number;
    done: boolean = false;
    chars_box: string = '';

    static readonly TYPE_FIND_SAME_CHAR = 'same_char'; // найди такую же букву
    static readonly TYPE_FIND_CHAR = 'char'; // найди нужную букву
    static readonly TYPE_WRITE_WORD = 'word'; // напиши слово

    static readonly HIDDEN_CHAR = '•';

    static readonly NO_IMAGE = '/assets/images/no-image.png';

    private _wordWithHiddenChars = {
        originalWord: '',
        originalDiff: 0,
        originalType: '',
        wordWithHiddenChars: '',
    };
    img_local_path?: string;
    private _calculatedCharactersToChoose = {
        charactersToChoose: [] as CharacterToChoose[],
        originalDiff: 0,
        originalType: '',
        originalWord: '',
        originalWordWithHiddenChars: ''
    }

    get charactersToChoose(): CharacterToChoose[] {
        if (this.chars_box) {
            return this.chars_box.toLowerCase().split('').map(char => {
                return {
                    char,
                    is_correct: !!this.word.toLowerCase().split('').find(c => c == char),
                };
            });
        }
        if (typeof this._calculatedCharactersToChoose === 'object' &&
            this._calculatedCharactersToChoose.originalWord == this.word &&
            this._calculatedCharactersToChoose.originalDiff == this.difficulty &&
            this._calculatedCharactersToChoose.originalType == this.type &&
            this._calculatedCharactersToChoose.originalWordWithHiddenChars == this.wordWithHiddenChars() &&
            this._calculatedCharactersToChoose.charactersToChoose.length)
        {
            return this._calculatedCharactersToChoose.charactersToChoose;
        }

        this._calculatedCharactersToChoose = {
            originalDiff: this.difficulty,
            originalType: this.type,
            originalWord: this.word,
            originalWordWithHiddenChars: this.wordWithHiddenChars(),
            charactersToChoose: calculateCharactersToChoose(this),
        };
        return this._calculatedCharactersToChoose.charactersToChoose;
    }

    wordWithHiddenChars(hiddenCharPos?: number): string {
        if (typeof this._wordWithHiddenChars === 'object' &&
            this._wordWithHiddenChars.originalWord == this.word &&
            this._wordWithHiddenChars.originalDiff == this.difficulty &&
            this._wordWithHiddenChars.originalType == this.type &&
            this._wordWithHiddenChars.wordWithHiddenChars.length == this.word.length)
        {
            return this._wordWithHiddenChars.wordWithHiddenChars;
        }
        const task = this;
        let word = task.word;

        if (word.split('').filter(char => !!char).length == 0) {
            return '';
        }

        const hiddenChar = Task.HIDDEN_CHAR;
        if (task.type == Task.TYPE_WRITE_WORD) {
            word = word.split('').map(_ => hiddenChar).join('');
        } else if (task.type == Task.TYPE_FIND_SAME_CHAR) {
            let index;
            if (task.difficulty == 1) {
                index = 0;
            } else {
                index = typeof(hiddenCharPos) == 'number' ? hiddenCharPos : Math.floor(Math.random() * word.length);
            }

            let splittedWord = word.split('');
            splittedWord[index] = hiddenChar;
            word = splittedWord.join('');

        } else if (task.type == Task.TYPE_FIND_CHAR) {
            let index = typeof(hiddenCharPos) == 'number' ? hiddenCharPos : Math.floor(Math.random() * word.length);
            let splittedWord = word.split('');
            splittedWord[index] = hiddenChar;
            word = splittedWord.join('');
        } else {
            word = '';
        }
        this._wordWithHiddenChars = {
            originalWord: this.word,
            wordWithHiddenChars: word,
            originalType: this.type,
            originalDiff: this.difficulty,
        };
        return word;
    }

    get imageSrc(): string {
        if (this.img_local_path) {
            return normalizeSrc(this.img_local_path);
        }
        if (this.img_external_path) {
            return this.img_external_path;
        }
        if (this.img_path) {
            return BACKEND_URL + this.img_path;
        }
        return Task.NO_IMAGE;
    }

    constructor(props: Partial<Task>) {

        const taskKeys = Object.keys(props);
        for (let key of taskKeys) {
            // @ts-ignore
            if (props[key]) {
                // @ts-ignore
                this[key] = props[key];
            }
        }
    }

    static fromJson(task: Partial<Task>) {
        return new Task(task);
    }

    setHiddenCharPos(index: number) {
        this._wordWithHiddenChars = {
            originalWord: this.word,
            originalDiff: this.difficulty,
            originalType: this.type,
            wordWithHiddenChars: '',
        };
        this.wordWithHiddenChars(index);
    }
}
