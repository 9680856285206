import {combineReducers} from "redux";
import {tasksReducer} from "./tasks/reducer";
import {levelsReducer} from "./levels/reducer";
import {uiReducer} from "./ui/reducer";

export const appReducer = combineReducers({
    tasks: tasksReducer,
    levels: levelsReducer,
    ui: uiReducer,
});
