import {CharacterToChoose} from "./types";
import {Task} from "../../models/task";

export function calculateCharactersToChoose(task: Task, iteration: number = 0): CharacterToChoose[] {

    if (iteration >= 15) {
        return [];
    }
    if (task.word.trim().length == 0) {
        return [];
    }
    if (task.wordWithHiddenChars().trim().length == 0) {
        return [];
    }

    const splittedCorrectWord = task.word.trim();
    const wordWithHiddenChars = task.wordWithHiddenChars();
    const correctChars = wordWithHiddenChars.split('').map((char, index) => {
        if (char != Task.HIDDEN_CHAR) {
            return '';
        } else {
            return splittedCorrectWord[index];
        }
    }).filter(c => !!c);
    let totalCharsCount: number;

    if (task.type == Task.TYPE_WRITE_WORD) {
        switch (task.difficulty) {
            case 1:
                totalCharsCount = task.word.length;
                break;
            case 2:
            case 4:
                totalCharsCount = task.word.length + 1;
                break;
            case 3:
            case 5:
                totalCharsCount = task.word.length + 3;
                break;
            default:
                totalCharsCount = task.word.length;
        }
    } else if (task.type == Task.TYPE_FIND_CHAR) {
        switch (task.difficulty) {
            case 1:
            case 2:
                totalCharsCount = task.word.length;
                break;
            case 3:
                totalCharsCount = task.word.length + 1;
                break;
            case 4:
                totalCharsCount = task.word.length + 3;
                break;
            default:
                totalCharsCount = task.word.length;
        }
    } else {
        totalCharsCount = task.word.length;
    }

    const alphabet = 'абвгдеёжзийклмнопрстуфхцчшщэюя';
    let result = [] as CharacterToChoose[];
    for (const char of correctChars) {
        result.push({char, is_correct: true});
    }

    while (result.length < totalCharsCount) {
        let randomChar: string;
        do {
            randomChar = alphabet[Math.floor(Math.random() * alphabet.length)];
        } while (!!result.find(c => c.char == randomChar));
        result.push({
            char: randomChar,
            is_correct: false,
        });
    }
    // shuffle
    result.sort(() => Math.random() - 0.5);

    const sameWord = result.map(r => r.char).join('').toLowerCase() == task.word.toLowerCase();
    const characterDuplicates = [Task.TYPE_FIND_SAME_CHAR, Task.TYPE_FIND_CHAR].includes(task.type) ? (
        result.filter((c, index, array) => {
            return array.findIndex(e => e.char == c.char) !== index;
        }).length > 0
    ) : false;

    if (sameWord || characterDuplicates) {
        return calculateCharactersToChoose(task, iteration + 1);
    }

    return result;
}
