import * as React from 'react';
import {Task} from "../../models/task";

export interface GameHeaderArgs {
    goBack: Function;
    levelTasks: Task[];
    totalTasksCompleted: number;
}

export function GameHeaderComponent({goBack, levelTasks, totalTasksCompleted}: GameHeaderArgs) {

    const starsRender = () => {
        const stars = levelTasks
            .sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)
            .filter(task => !!task.word.trim())
            .map((task, i) => {
                const starImage = task.done ? "/assets/images/star-1.svg" : '/assets/images/star-0.svg';
                return (
                    <li className="star-icon" key={i}>
                        <img src={starImage} alt=""/>
                    </li>
                );
            });
        return (
            <ul className="list-stars">
                {stars}
            </ul>
        );
    }
    return (
        <div className="header">
            <div className="container">
                <a onClick={() => goBack()} className="btn-back">
                    <span>
                        <img src={"/assets/images/arrow-left.svg"} alt=""/>
                    </span>
                </a>

                {starsRender()}

                <div className="letter">
                    <div className="letter-icon">
                        <img src={"/assets/images/star-white.svg"} alt=""/>
                    </div>
                    <div className="letter-num">{totalTasksCompleted}</div>
                </div>
            </div>
        </div>
    );
}
