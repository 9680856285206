import * as React from 'react';
import {Task} from "../../models/task";
import {Level} from "../../models/level";
import GameComponent from "../../components/Game/GameComponent";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {taskDone} from "../../state/tasks/actions";
import {History} from 'history';
import {IonContent} from "@ionic/react";
import {playTaskCompletedSound} from "../../state/ui/actions";
import CrossGestureHandler from "../../components/CrossGestureHandler/CrossGestureHandler";
import {appStorage} from "../../services/AppStorage";
import {Settings} from "../../models/settings";


interface GameScreenProps {
    match: any,
    history: History,
    allTasks: Task[];
    levels: Level[];
    onTaskDone: (task_id: number) => void;
    playTaskCompletedSound: Function;
}

interface GameScreenState {
    task_id?: number;
    task?: Task,
    level?: Level,
    levelTasks: Task[],
    settings: Settings,
}

class GameScreen extends React.Component<GameScreenProps, GameScreenState> {
    private redirectTimeout?: NodeJS.Timeout;
    private redirectUrl?: string;

    constructor(props: GameScreenProps) {
        super(props);
        this.state = {
            task_id: undefined,
            task: undefined,
            level: undefined,
            levelTasks: [],
            settings: {} as Settings,
        };

        appStorage.getSettings().then(settings => {
            this.setState(prev => ({...prev, settings}));
        })
    }

    componentDidMount() {
        this.recalculateState();
    }

    componentWillUnmount() {
        if (this.redirectTimeout) {
            clearTimeout(this.redirectTimeout);
        }
    }

    componentDidUpdate(prevProps: Readonly<GameScreenProps>, prevState: Readonly<GameScreenState>, snapshot?: any) {
        if (prevProps.match.params.task_id != this.props.match.params.task_id
            || prevProps.allTasks != this.props.allTasks
            || prevProps.levels.length != this.props.levels.length
        ) {
            this.recalculateState();
        }
    }

    private async recalculateState() {
        const newState: Partial<GameScreenState> = {
            task: undefined,
            level: undefined,
            levelTasks: [],
            task_id: Number(this.props.match.params.task_id),
            settings: await appStorage.getSettings(),
        };

        newState.task = this.props.allTasks.find(t => t.id == newState.task_id);
        if (!newState.task) {
            this.setState(prev => ({...prev, ...newState}))
            return;
        }
        newState.level = this.props.levels.find(l => l.id == newState.task!.level_id);
        if (!newState.level) {
            this.setState(prev => ({...prev, ...newState}))
            return;
        }
        newState.levelTasks = this.props.allTasks.filter(t => t.level_id == newState.level!.id)
        this.setState(prev => ({...prev, ...newState}));
    }

    goBack() {
        const {history} = this.props;
        history.push(`/level/${this.state.level!.id}`);
    }

    onFail() {
        if (this.redirectUrl && this.redirectTimeout) {
            return;
        }
        if (typeof this.state.task_id === 'number') {
            clearTimeout(this.redirectTimeout!);
            const nextTask = this.getNextTask();
            if (nextTask) {
                this.redirectUrl = `/tasks/${nextTask.id}`;
            }

            if (this.redirectUrl) {
                this.redirectTimeout = setTimeout(() => {
                    this.props.history.push(this.redirectUrl!);
                    this.redirectUrl = undefined;
                    this.redirectTimeout = undefined;
                }, 2000);
            }
        }
    }

    onComplete() {
        if (this.redirectUrl && this.redirectTimeout) {
            return;
        }
        if (typeof this.state.task_id === 'number') {
            const totalCompletedTasks = this.props.allTasks.filter(t => t.done).length;
            const openingLevel = this.props.levels.find(l => l.completed_tasks_required == totalCompletedTasks + 1);

            const nextTask = this.getNextTask();

            this.props.onTaskDone(this.state.task_id);

            this.props.playTaskCompletedSound();

            if (openingLevel) {
                this.redirectUrl = `/reward/${openingLevel.id}`;
            } else if (nextTask) {
                this.redirectUrl = `/tasks/${nextTask.id}`;
            }

            clearTimeout(this.redirectTimeout!);
            if (this.redirectUrl) {
                this.redirectTimeout = setTimeout(() => {
                    this.props.history.push(this.redirectUrl!);
                    this.redirectUrl = undefined;
                    this.redirectTimeout = undefined;
                }, 2000);
            }
        }
    }

    private skipAnimation() {
        if (this.redirectUrl) {
            clearTimeout(this.redirectTimeout!);
            this.props.history.push(this.redirectUrl);
            this.redirectUrl = undefined;
            this.redirectTimeout = undefined;
        }
    }

    private contentRender() {
        const task = this.state.task;
        if (!task) {
            return null;
        }

        const level = this.state.level;
        if (!level) {
            return null;
        }

        const levelTasks = this.state.levelTasks;
        if (!levelTasks) {
            return null;
        }

        return <GameComponent
            key={this.props.match.task_id}
            tasks={this.props.allTasks}
            onComplete={() => this.onComplete()}
            onFail={() => this.onFail()}
            onSkipAnimation={() => this.skipAnimation()}
            goBack={() => this.goBack()}
            level={level}
            task={task}
            levelTasks={levelTasks}/>;
    };


    private getNextTask(): Task | null {

        const getSortedTasks = (tasks: Task[]) => {
            return [...tasks].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
        }

        const currentLevel = this.state.level!;
        const currentTask = this.state.task!;
        const allTasks = getSortedTasks(this.props.allTasks);
        const allLevels = [...this.props.levels].sort((a, b) => a.level_number > b.level_number ? 1 : -1);
        const currentLevelTasks = getSortedTasks(allTasks.filter(t => t.level_id == currentLevel.id));


        const getNextLevel = () => allLevels.find(l => l.level_number == currentLevel.level_number + 1);

        const getNextLevelTasks = () => {
            const nextLevel = getNextLevel();
            if (!nextLevel) {
                return null;
            }
            return allTasks.filter(t => t.level_id == nextLevel.id);
        }

        const getNextUndone = () =>
            currentLevelTasks
                .find(t => !t.done && t.sort_order > currentTask!.sort_order);

        const getFirstUndone = () =>
            currentLevelTasks.find(t => !t.done && t.id != currentTask.id);

        const getNextInCurrentLevel = () =>
            currentLevelTasks.find(t => t.sort_order == currentTask.sort_order + 1);

        const getFirstUndoneInNextLevel = () => {
            const tasks = getNextLevelTasks();
            if (!tasks) {
                return null;
            }
            return tasks.find(t => !t.done);
        };

        const getFirstInNextLevel = () => {
            const tasks = getNextLevelTasks();
            if (!tasks) {
                return null;
            }
            return [...tasks].shift() || null;
        };

        const settings = this.state.settings;
        let nextTask;
        if (settings && settings.cardCompletion) {
            if (settings.cardCompletion == 'byOrder') {
                nextTask = getNextInCurrentLevel() || getFirstInNextLevel();
            } else if (settings.cardCompletion == 'notCompleted') {
                nextTask = getNextUndone() || getFirstUndone() || getFirstUndoneInNextLevel() || getFirstInNextLevel();
            }
        }

        return nextTask || getNextUndone() || getFirstUndone() || getNextInCurrentLevel() || getFirstUndoneInNextLevel() || getFirstInNextLevel();
    }


    render() {
        return (
            <IonContent>
                {this.contentRender()}
                <CrossGestureHandler onGoToParentMode={() => this.goToParentMode()}
                                     onSetParentPassword={(password) => this.setNewPassword(password)}
                                     settings={this.state.settings}/>
            </IonContent>
        );
    }

    private goToParentMode() {
        this.props.history.replace('/parent/levels');
    }

    private setNewPassword(password: string) {
        appStorage.setParentPassword(password);
    }
}

const mapStateToProps = (state: any) => {
    const {levels, tasks} = state;
    return {
        levels: levels.levels,
        allTasks: tasks.tasks,
    };
};
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        onTaskDone: taskDone,
        playTaskCompletedSound,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(GameScreen);
