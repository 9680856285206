import React from 'react'
import AnimationOne from "./Bubbles/AnimationOne";
import AnimationTwo from "./Bubbles/AnimationTwo";
import AnimationThree from "./Bubbles/AnimationThree";
import AnimationFour from "./Bubbles/AnimationFour";
import {AnimationFive} from "./Bubbles/AnimationFive";
import TimedOut from "./TimedOutComponent";

interface BubblesRewardComponentProps {
    animationNumber: number;
    timeoutSeconds: number;
    onTimedOut: Function;
    onCompleted: Function;
}

export default function BubblesRewardComponent(props: BubblesRewardComponentProps) {
    let [timedOut, setTimedOut] = React.useState(false);

    const onTimedOut = () => {
        setTimedOut(true);
        props.onTimedOut();
    }

    if (timedOut) {
        return <TimedOut onClick={props.onCompleted}/>
    } else {
        return <BubblesRewardAnimation
            animationNumber={props.animationNumber}
            timeoutSeconds={props.timeoutSeconds}
            onTimeout={() => onTimedOut()}
            onSkip={() => onTimedOut()}
        />
    }
}

interface BubblesAnimationComponentProps {
    animationNumber: number;
    timeoutSeconds: number;
    onTimeout: Function;
    onSkip: Function;
}

interface BubblesAnimationComponentState {
    interval: NodeJS.Timeout | null,
    remainingSeconds: number,
}

class BubblesRewardAnimation extends React.Component<BubblesAnimationComponentProps, BubblesAnimationComponentState> {

    constructor(props: BubblesAnimationComponentProps) {
        super(props);
        this.state = {
            interval: null,
            remainingSeconds: this.props.timeoutSeconds,
        };
    }

    get animationNumber() {
        if (this.props.animationNumber < 1 || this.props.animationNumber > 5) {
            return 1;
        }
        return this.props.animationNumber;
    }

    startInterval() {
        if (!this.state.interval && this.state.remainingSeconds > 0) {
            const interval = setInterval(() => {
                let remainingSeconds = this.state.remainingSeconds;
                if (remainingSeconds <= 1) {
                    clearInterval(this.state.interval!);
                    this.setState(prev => ({...prev, interval: null}));
                    this.props.onTimeout();
                }
                remainingSeconds--;
                this.setState(prev => ({...prev, remainingSeconds}));
            }, 1000);

            this.setState(prev => ({...prev, interval}));
        }
    }

    componentDidMount() {
        this.startInterval();
    }

    timerRender() {
        let remainingSeconds = this.state.remainingSeconds;
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;

        const minutesSecondDigit = minutes % 10;
        const minutesFirstDigit = minutes < 10 ? 0 : ((minutes - minutesSecondDigit) / 10);

        const secondsSecondDigit = seconds % 10;
        const secondsFirstDigit = seconds < 10 ? 0 : ((seconds - secondsSecondDigit) / 10);

        return (
            <div className="timer-wrapper">
                <div className="timer-box">
                    <span>{minutesFirstDigit}</span>
                    <span>{minutesSecondDigit}</span>
                </div>
                <div className="timer-dots">
                    <img src={"/assets/images/dots.svg"} alt=""/>
                </div>
                <div className="timer-box">
                    <span>{secondsFirstDigit}</span>
                    <span>{secondsSecondDigit}</span>
                </div>
            </div>
        );
    };

    renderAnimation() {
        const props = {
            width: '100%',
            height: '100%',
        };
        switch (this.animationNumber) {
            case 1:
                return <AnimationOne {...props} />;
            case 2:
                return <AnimationTwo {...props}/>;
            case 3:
                return <AnimationThree {...props}/>;
            case 4:
                return <AnimationFour {...props}/>;
            case 5:
                return <AnimationFive {...props}/>;
        }
        return <AnimationOne {...props}/>;
    }

    render() {
        return (
            <div className="wrapper">
                <div className="header">
                    <div className="container">
                        {this.timerRender()}
                        <div className="links-skip" onClick={() => this.props.onSkip()}>Пропустить</div>
                    </div>
                </div>

                <div className="content">
                    <div className="container">
                        <div className="animation-wrapper">
                            {this.renderAnimation()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
