import React from 'react';
import Stats from "./animatonsJs/animation2";


export default class AnimationTwo extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.canvasRef = React.createRef();
    }

    render() {
        return (
            <div style={{height: this.props.height, width: this.props.width}} ref={this.wrapperRef}>
                <canvas ref={this.canvasRef}/>
            </div>
        );
    }

    componentDidMount() {
        setTimeout(() => {
            this.startAnimation();
        }, 200);
    }

    startAnimation() {
        const canvas = this.canvasRef.current,
            context = canvas.getContext("2d"),
            colorPallete = ["#ff1783", "#17c9ff", "#36ff40"],
            stats = new Stats();
        // div = document.getElementById("stats")

        // div.appendChild(stats.domElement);

        var width = (canvas.width = this.wrapperRef.current.clientWidth),
            height = (canvas.height = this.wrapperRef.current.clientHeight),
            origin = {x: width / 2, y: height / 2},
            mouse = {x: width / 2, y: height / 2},
            balls = [],
            count = 0,
            randomCount = 1;

        this.resize = () => {
            width = canvas.width = this.wrapperRef.current.clientWidth;
            height = canvas.height = this.wrapperRef.current.clientHeight;
            origin = {x: width / 2, y: height / 2};
        };

        class Ball {
            constructor() {
                this.x = origin.x;
                this.y = origin.y;
                this.angle = Math.PI * 2 * Math.random();
                this.vx = (1.3 + Math.random() * 0.3) * Math.cos(this.angle);
                this.vy = (1.3 + Math.random() * 0.3) * Math.sin(this.angle);
                this.r = 6 + 3 * Math.random();
                this.color = colorPallete[Math.floor(Math.random() * colorPallete.length)];
            }

            update() {
                this.x += this.vx;
                this.y += this.vy;
                this.r -= 0.01;
            }
        }


        const loop = () => {
            stats.begin();
            context.clearRect(0, 0, width, height);
            if (count === randomCount) {
                balls.push(new Ball());
                count = 0;
                randomCount = 3 + Math.floor(Math.random() * 5);
            }
            count++;
            for (var i = 0; i < balls.length; i++) {
                var b = balls[i];
                context.fillStyle = b.color;
                context.beginPath();
                context.arc(b.x, b.y, b.r, 0, Math.PI * 2, false);
                context.fill();
                b.update();
            }

            origin.x += (mouse.x - origin.x) * 0.15;
            origin.y += (mouse.y - origin.y) * 0.15;

            context.fillStyle = "#ffdd02";
            context.beginPath();
            context.arc(origin.x, origin.y, 40, 0, Math.PI * 2, false);
            context.fill();

            removeBall();
            stats.end();
            if (!this.done) {
                requestAnimationFrame(loop);
            }
        }

        loop();

        function removeBall() {
            for (var i = 0; i < balls.length; i++) {
                var b = balls[i];
                if (
                    b.x + b.r < 0 ||
                    b.x - b.r > width ||
                    b.y + b.r < 0 ||
                    b.y - b.r > height ||
                    b.r <= 0
                ) {
                    balls.splice(i, 1);
                }
            }
        }

        const pointerMove = (e) => {
            mouse.x = e.touches ? (e.touches[0].layerX || e.layerX) : e.layerX;
            mouse.y = e.touches ? (e.touches[0].layerY || e.layerY) : e.layerY;
            e.stopPropagation();
            e.preventDefault();
        };
        canvas.addEventListener("mousemove", pointerMove, false);
        canvas.addEventListener("touchmove", pointerMove, false);

        this.wrapperRef.current.onresize = () => {
            this.resize();
        };
    }

    done = false;
    componentWillUnmount() {
        this.done = true;
    }
}
