import React, {ElementRef} from 'react';
import {Level} from "../../models/level";
import {Task} from "../../models/task";
import ParentTaskItem from "./ParentTaskItem";
import LevelSettings from "./LevelSettings";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
// @ts-ignore
import Preview from 'react-dnd-preview';
import {Camera} from "@ionic-native/camera";
import {saveLocalFile} from "../../App";
import {IonAlert} from "@ionic/react";
import {Motion, spring} from 'react-motion';

interface ParentTaskListProps {
    level: Level,
    levelTasks: Task[],
    onGoBack: Function,
    onLevelChange: (changes: Partial<Level>) => void,
    onDropProgress: Function,
    onGoToTask: (task: Task) => void,
    onDeleteLevel: () => void,
    onAddCard: () => void,
    onScrollTo: (el: ElementRef<any>) => void,
    onSwap: (task_id_a: number, task_id_b: number) => void,
}

interface ParentTaskListState {
    showGetVideoModal: boolean;
}

const DRAG_DELAY = 150;

export default class ParentTaskList extends React.Component<ParentTaskListProps, ParentTaskListState> {


    constructor(props: any) {
        super(props);
        this.state = {
            showGetVideoModal: false,
        }
    }

    hideVideoModal() {
        this.setState(prev => ({...prev, showGetVideoModal: false}));
    }

    showVideoModal() {
        this.setState(prev => ({...prev, showGetVideoModal: true}));
    }

    renderTaskItems() {
        return this.props.levelTasks.map((task, i) => {
            return (
                <Motion
                    key={task.id}
                    style={{y: spring(i * 116, {stiffness: 500, damping: 32})}}
                >
                    {({y}) =>
                        <ParentTaskItem
                            onTaskClick={() => this.props.onGoToTask(task)}
                            moveTask={this.moveTask.bind(this)}
                            task={task}
                            style={{
                                transform: 'translate3d(0, ' + y + 'px, 0)',
                            }}
                            index={i}
                            level={this.props.level}
                            key={task.id}/>
                    }
                </Motion>
            );
        });
    }

    private moveTask(dragIndex: number, hoverIndex: number) {
        this.props.onSwap(
            this.props.levelTasks[dragIndex]!.id!,
            this.props.levelTasks[hoverIndex]!.id!,
        );
    }


    private generatePreview({itemType, item, style}: any) {
        const task = this.props.levelTasks.find(t => t.id == item.id);
        if (!task) {
            return null;
        }
        return (<>
            <div className="preview" style={style}>
                <div className="bg-card-overlay">
                    <p>{task.word}</p>
                </div>
            </div>
        </>);
    }

    componentDidMount() {
    }

    loadVideo(source: number) {
        Camera.getPicture({
            mediaType: Camera.MediaType.VIDEO,
            sourceType: source,
            allowEdit: false,
        }).then(filePath => {
            console.log({'Video Result': filePath});
            saveLocalFile(filePath).then(videoPath => {
                this.props.onLevelChange({local_video_path: videoPath});
            });
        });
    }

    render() {
        return (
            <>
                <div className="wrapper">
                    <div className="header">
                        <div className="container">
                            <a className="btn-back" onClick={() => this.props.onGoBack()}>
                            <span>
                                <img src="/assets/images/arrow-left.svg" alt=""/>
                            </span>
                            </a>
                            <span className="level-number">
                                Уровень {this.props.level.level_number}
                            </span>
                            <a className="btn-delete-card" onClick={() => this.props.onDeleteLevel()}>
                                <img src="/assets/images/delete2.svg" alt=""/>
                            </a>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container">

                            <div className="card-line-container" style={{height: this.props.levelTasks.length * 120 + 'px'}}>
                            <DndProvider backend={TouchBackend} options={{
                                delay: DRAG_DELAY,
                                enableMouseEvents: true,
                                ignoreContextMenu: true
                            }}>
                                {this.renderTaskItems()}
                                <Preview generator={this.generatePreview.bind(this)}/>
                            </DndProvider>
                            </div>

                            <div className="card-line--card-add" onClick={() => {
                                this.props.onAddCard()
                            }}>
                                <div className="card-line__name">
                                    <h4>Добавить карточку</h4>
                                </div>
                            </div>

                            <LevelSettings level={this.props.level}
                                           onScrollTo={this.props.onScrollTo}
                                           onLoadVideo={() => this.showVideoModal()}
                                           onChange={changes => this.props.onLevelChange(changes)}
                                           onDropProgress={() => this.props.onDropProgress()}/>

                        </div>
                    </div>
                </div>

                <IonAlert
                    isOpen={this.state.showGetVideoModal}
                    onDidDismiss={() => this.hideVideoModal()}
                    header={'Загрузить видео'}
                    buttons={[
                        {
                            text: 'Из галереи',
                            handler: () => {
                                this.loadVideo(Camera.PictureSourceType.PHOTOLIBRARY);
                            },
                        },
                        {
                            text: 'Камера',
                            handler: () => {
                                this.loadVideo(Camera.PictureSourceType.CAMERA);
                            },
                        },
                    ]}
                />
            </>
        );
    }
}
