import {
    ADD_TASK_ACTION,
    ADD_TASKS_ACTION, DROP_ALL_PROGRESS_ACTION, REMOVE_TASK_ACTION,
    SET_TASK_HIDDEN_CHAR_ACTION,
    SET_TASK_LOCAL_IMG_PATH,
    TASK_DONE_ACTION,
    UPDATE_TASK_ACTION
} from "./actions";
import {Task} from "../../models/task";
import {appStorage} from "../../services/AppStorage";
import {DROP_PROGRESS_ACTION, REMOVE_LEVEL_ACTION} from "../levels/actions";
import {Level} from "../../models/level";

const INITIAL_STATE = {
    tasks: [] as Task[],
};

type TasksState = typeof INITIAL_STATE;


export const tasksReducer = (state: TasksState = INITIAL_STATE, action: any): TasksState => {

    let newState = state;
    switch (action.type) {
        case TASK_DONE_ACTION:
            newState = taskDone(state, action.task_id);
            break;
        case ADD_TASKS_ACTION:
            newState = addTasks(state, action.tasks);
            break;
        case SET_TASK_LOCAL_IMG_PATH:
            newState = setLocalImgPath(state, action.task_id, action.img_local_path);
            break;
        case DROP_PROGRESS_ACTION:
            newState = dropProgress(state, action.level as Level);
            break;
        case UPDATE_TASK_ACTION:
            newState = updateTask(state, action.task as Task);
            break;
        case ADD_TASK_ACTION:
            newState = addTask(state, action.task as Task);
            break;
        case SET_TASK_HIDDEN_CHAR_ACTION:
            newState = setTaskHiddenChar(state, action.task_id as number, action.index as number);
            break;
        case REMOVE_LEVEL_ACTION:
            newState = removeLevel(state, action.level_id as number);
            break;
        case REMOVE_TASK_ACTION:
            newState = removeTask(state, action.task_id as number);
            break;
        case DROP_ALL_PROGRESS_ACTION:
            newState = dropAllProgress(state);
            break;
    }

    if (newState !== state) {
        newState = saveToStorage(sort(newState));
    }

    return newState;
};

function sort(state: TasksState) {
    return {
        ...state,
        tasks: state.tasks.sort((a, b) => a.sort_order > b.sort_order ? 1 : -1),
    };
}

function saveToStorage(state: TasksState) {
    appStorage.setTasks(state.tasks);
    return state;
}

function taskDone(state: TasksState, task_id: number) {
    let currentTask = state.tasks.find(t => t.id == task_id);
    if (!currentTask) {
        return state;
    }

    appStorage.completeTask(task_id);

    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.id != task_id),
            Object.assign(new Task({}), currentTask, {done: true}),
        ],
    };
}

function addTasks(state: TasksState, tasks: Task[]) {
    return {
        ...state,
        tasks: [
            ...state.tasks.filter(l => !tasks.find(l2 => l2.id == l.id)),
            ...tasks,
        ],
    };
}


function setLocalImgPath(state: TasksState, task_id: number, img_local_path: string) {
    let task: Task;
    if (!(task = state.tasks.find(t => t.id == task_id)!)) {
        return state;
    }

    task = new Task(task);
    task.img_local_path = img_local_path;

    appStorage.setTaskLocalImg(task.id!, task.img_local_path);

    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.id != task_id),
            task,
        ],
    };
}

function dropProgress(state: TasksState, level: Level) {
    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.level_id != level.id),
            ...state.tasks.filter(t => t.level_id == level.id).map(task => {
                task.done = false;
                return task;
            }),
        ],
    };
}

function updateTask(state: TasksState, task: Task) {
    let foundTask = state.tasks.find(t => t.id == task.id);
    if (!foundTask) {
        return state;
    }
    foundTask = Object.assign(new Task(foundTask), task);

    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.id != task.id),
            foundTask,
        ],
    };
}

function addTask(state: TasksState, task: Task): TasksState {
    const foundTask = state.tasks.find(t => t.id == task.id);
    if (foundTask) {
        return state;
    }
    return {
        ...state,
        tasks: [...state.tasks, task],
    };
}

function setTaskHiddenChar(state: TasksState, task_id: number, index: number) {
    let task = state.tasks.find(t => t.id == task_id);
    if (!task) {
        return state;
    }
    let updatedTask = new Task(task);
    updatedTask.setHiddenCharPos(index);
    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.id != task_id),
            updatedTask,
        ]
    };
}

function removeLevel(state: TasksState, level_id: number) {
    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.level_id != level_id),
        ],
    };
}

function removeTask(state: TasksState, task_id: number): TasksState {
    return {
        ...state,
        tasks: [
            ...state.tasks.filter(t => t.id != task_id),
        ],
    };
}

function dropAllProgress(state: TasksState) {
    return {
        ...state,
        tasks: [
            ...state.tasks.map(originalTask => {
                let task = Object.assign(new Task(originalTask), originalTask);
                task.done = false;
                return task;
            }),
        ],
    };
}
