import React from 'react';


export class AnimationFive extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }


    render() {
        return (
            <div ref={this.wrapperRef} style={{width: this.props.width, height: this.props.height}}>
                <canvas id="scene"/>
            </div>
        );
    }

    componentDidMount() {
        setTimeout(() => {
            this.start();
        }, 400);
    }

    start() {
        const wrapperRef = this.wrapperRef;
        var canvas = document.querySelector("#scene"),
            ctx = canvas.getContext("2d"),
            particles = [],
            amount = 0,
            mouse = {x: 0, y: 0},
            radius = 1;

        var colors = ["#468966", "#FFF0A5", "#FFB03B", "#B64926", "#8E2800"];

        var copy = document.querySelector("#copy");

        var ww = canvas.width = (wrapperRef.current.clientWidth || 1);
        var wh = canvas.height = (wrapperRef.current.clientHeight || 1);

        function Particle(x, y) {
            this.x = Math.random() * ww;
            this.y = Math.random() * wh;
            this.dest = {
                x: x,
                y: y
            };
            this.r = Math.random() * 2 + 2;
            this.vx = (Math.random() - 0.5) * 5;
            this.vy = (Math.random() - 0.5) * 5;
            this.accX = 0;
            this.accY = 0;
            this.friction = Math.random() * 0.05 + 0.94;

            this.color = colors[Math.floor(Math.random() * 6)];
        }

        Particle.prototype.render = function () {


            this.accX = (this.dest.x - this.x) / 1000;
            this.accY = (this.dest.y - this.y) / 1000;
            this.vx += this.accX;
            this.vy += this.accY;
            this.vx *= this.friction;
            this.vy *= this.friction;

            this.x += this.vx;
            this.y += this.vy;

            ctx.fillStyle = this.color;
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.r, Math.PI * 2, false);
            ctx.fill();

            var a = this.x - mouse.x;
            var b = this.y - mouse.y;

            var distance = Math.sqrt(a * a + b * b);
            if (distance < (radius * 70)) {
                this.accX = (this.x - mouse.x) / 100;
                this.accY = (this.y - mouse.y) / 100;
                this.vx += this.accX;
                this.vy += this.accY;
            }

        }

        function onMouseMove(e) {
            mouse.x = e.clientX;
            mouse.y = e.clientY;
        }

        function onTouchMove(e) {
            if (e.touches.length > 0) {
                mouse.x = e.touches ? (e.touches[0].layerX || e.layerX) : e.layerX;
                mouse.y = e.touches ? (e.touches[0].layerY || e.layerY) : e.layerY;
            }
            e.stopPropagation();
            e.preventDefault();
        }

        function onTouchEnd(e) {
            mouse.x = -9999;
            mouse.y = -9999;
        }

        function initScene() {
            ww = canvas.width = wrapperRef.current.clientWidth;
            wh = canvas.height = wrapperRef.current.clientHeight;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.font = "bold " + (ww / 10) + "px sans-serif";
            ctx.textAlign = "center";
            ctx.fillText("Пиши читай", ww / 2, wh / 2);

            var data = ctx.getImageData(0, 0, ww, wh).data;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = "screen";

            particles = [];
            for (var i = 0; i < ww; i += Math.round(ww / 150)) {
                for (var j = 0; j < wh; j += Math.round(ww / 150)) {
                    if (data[((i + j * ww) * 4) + 3] > 150) {
                        particles.push(new Particle(i, j));
                    }
                }
            }
            amount = particles.length;

        }

        function onMouseClick() {
            radius++;
            if (radius === 5) {
                radius = 0;
            }
        }

        const render = (a) => {
            if (this.done) {
                return;
            }
            requestAnimationFrame(render);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (var i = 0; i < amount; i++) {
                particles[i].render();
            }
        };

        canvas.addEventListener("mousemove", onMouseMove);
        canvas.addEventListener("touchmove", onTouchMove);
        canvas.addEventListener("click", onMouseClick);
        canvas.addEventListener("touchend", onTouchEnd);
        initScene();
        requestAnimationFrame(render);
    }


    done = false;
    componentWillUnmount() {
        this.done = true;
    }
}
