import * as React from 'react';
import {PresentOpensSequence} from "../Sequences/Sequences";
import TimedOut from "./TimedOutComponent";
import {WebView} from "@ionic-native/ionic-webview";

interface YoutubeRewardComponentProps {
    youtubeUrl?: YoutubePlayerComponentProps["youtubeUrl"];
    localUrl?: YoutubePlayerComponentProps["localUrl"];
    timeoutSeconds: YoutubePlayerComponentProps["timeoutSeconds"];
    onCompleted: Function;
    onTimedOut: Function;
}


export default function YoutubeRewardComponent(props: YoutubeRewardComponentProps) {
    let [timedOut, setTimedOut] = React.useState(false);

    const onTimedOut = () => {
        setTimedOut(true);
        props.onTimedOut();
    }

    if (timedOut) {
        return <TimedOut onClick={props.onCompleted}/>
    } else {
        return <YoutubePlayerComponent
            youtubeUrl={props.youtubeUrl}
            localUrl={props.localUrl}
            timeoutSeconds={props.timeoutSeconds}
            onTimeout={() => onTimedOut()}
            onSkip={() => onTimedOut()}
        />
    }
}

interface YoutubePlayerComponentProps {
    localUrl?: string;
    youtubeUrl?: string;
    timeoutSeconds: number;
    onTimeout: Function;
    onSkip: Function;
}


interface YoutubePlayerComponentState {
    interval: NodeJS.Timeout | null,
    remainingSeconds: number,
    youtubeLayout: { width: number, height: number } | null,
    player: any,
    isPaused: boolean,
}

class YoutubePlayerComponent extends React.Component<YoutubePlayerComponentProps, YoutubePlayerComponentState> {

    private tag?: HTMLScriptElement;
    private videoRef?: React.RefObject<HTMLVideoElement>;

    constructor(props: YoutubePlayerComponentProps) {
        super(props);
        this.state = {
            interval: null,
            remainingSeconds: props.timeoutSeconds,
            youtubeLayout: null,
            player: null,
            isPaused: true,
        };
        this.videoRef = React.createRef<HTMLVideoElement>();
    }

    componentWillUnmount() {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
        if (this.state.player) {
            this.state.player.destroy();
        }
        this.tag?.remove();
    }

    startInterval() {
        if (!this.state.interval && this.state.remainingSeconds > 0) {
            const interval = setInterval(() => {
                if (this.state.isPaused) {
                    return;
                }
                let remainingSeconds = this.state.remainingSeconds;
                if (remainingSeconds <= 1) {
                    clearInterval(this.state.interval!);
                    this.setState(prev => ({...prev, interval: null}));
                    this.props.onTimeout();
                }
                remainingSeconds--;
                this.setState(prev => ({...prev, remainingSeconds}));
            }, 1000);

            this.setState(prev => ({...prev, interval}));
        }
    }

    componentDidMount() {
        if (!this.props.localUrl && !this.props.youtubeUrl) {
            return;
        }
        if (this.props.localUrl) {
            this.setState(prev => ({...prev, isPaused: false}));
            this.startInterval();
        } else {
            this.setState(prev => ({...prev, player: null}));
            (window as any).onYouTubeIframeAPIReady = () => {
                this.initPlayer();
            }
            this.loadYoutubeScript();
            this.startInterval();
        }
    }

    initPlayer() {
        let player = new (window as any).YT.Player('youtube', {
            height: '100%',
            width: '100%',
            videoId: this.getVideoId(),
            host: 'https://www.youtube.com',
            playerVars: {
                'fs': '0',
                'controls': '0',
                'playsinline': '1',
                'showinfo': '0',
                'modestbranding': '1',
                'autohide': '1',
                'autoplay': '1',
                'origin': window.location.origin,
            },
            events: {
                'onReady': (event: any) => {
                    event.target.playVideo();
                },
                'onStateChange': (event: any) => {
                    let isPaused = true;
                    if (event.data == (window as any).YT.PlayerState.PLAYING) {
                        isPaused = false;
                    }
                    this.setState(prev => ({...prev, isPaused}));
                },
                'onError': (event: any) => {
                    console.error({youtubeError: event});
                },
            }
        });
        this.setState(prev => ({...prev, player}));
    }


    loadYoutubeScript() {
        if ((window as any).YT) {
            (window as any).onYouTubeIframeAPIReady();
            return;
        }
        let tag = this.tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
    }


    timerRender() {
        let remainingSeconds = this.state.remainingSeconds;
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;

        const minutesSecondDigit = minutes % 10;
        const minutesFirstDigit = minutes < 10 ? 0 : ((minutes - minutesSecondDigit) / 10);

        const secondsSecondDigit = seconds % 10;
        const secondsFirstDigit = seconds < 10 ? 0 : ((seconds - secondsSecondDigit) / 10);

        return (
            <div className="timer-wrapper">
                <div className="timer-box">
                    <span>{minutesFirstDigit}</span>
                    <span>{minutesSecondDigit}</span>
                </div>
                <div className="timer-dots">
                    <img src={"/assets/images/dots.svg"} alt=""/>
                </div>
                <div className="timer-box">
                    <span>{secondsFirstDigit}</span>
                    <span>{secondsSecondDigit}</span>
                </div>
            </div>
        );
    };

    togglePause() {
        let player = this.props.localUrl ? this.videoRef?.current : this.state.player;
        if (player) {
            if (this.state.isPaused) {
                if (this.props.localUrl) {
                    (player as HTMLVideoElement).play();
                    this.setState(prev => ({...prev, isPaused: false}));
                } else {
                    player.playVideo();
                }
            } else {
                if (this.props.localUrl) {
                    (player as HTMLVideoElement).pause();
                    this.setState(prev => ({...prev, isPaused: true}));
                } else {
                    player.pauseVideo();
                }
            }
        }
    }

    render() {
        return (
            <div className="wrapper">
                <div className="header">
                    <div className="container">
                        {this.timerRender()}
                        <div className="links-skip" onClick={() => this.props.onSkip()}>Пропустить</div>
                    </div>
                </div>

                <div className="content">
                    <div className="container">
                        <div className="animation-wrapper">
                            <div className="video-box">
                                <div className="video-box__youtube-player-hider" onClick={() => this.togglePause()} />
                                {this.renderVideoFrame()}
                            </div>
                            <a className="btn btn-accent" onClick={() => this.props.onSkip()}>
                                <span>Пропустить</span>
                            </a>

                            <div className="animation-img">
                                <PresentOpensSequence onDone={() => {}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private static youtubeFrame() {
        return (
            <div id="youtube" />
        );
    }

    private getVideoId() {
        let originalUrl = this.props.youtubeUrl;
        if (!originalUrl) {
            return '';
        }
        let videoId: string;
        if (originalUrl.match('youtu.be')) {
            videoId = originalUrl.replace(/.+youtu\.be\/([a-zA-Z0-9_-]+).*/, '$1');
        } else if (originalUrl.match('youtube.com')) {
            videoId = originalUrl.replace(/.+v=([a-zA-Z0-9_-]+).*/, '$1');
        } else {
            console.error('Wrong url', originalUrl);
            videoId = '';
        }

        return videoId;
    }

    private renderVideoFrame() {
        if (this.props.localUrl) {
            return <video autoPlay
                          playsInline
                          loop
                          ref={this.videoRef}
                          src={WebView.convertFileSrc(this.props.localUrl)}
                          width="100%"
                          height="100%"/>
        } else if (this.props.youtubeUrl) {
            return <>{YoutubePlayerComponent.youtubeFrame()}</>;
        }
    }
}
