import * as React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import {connect} from "react-redux";
import {History} from "history";
import {Level} from "../../../models/level";
import {Task} from "../../../models/task";
import {match} from 'react-router';
import {LevelListComponent} from "../../../components/LevelList/LevelListComponent";
import {bindActionCreators} from "redux";
import {addLevel, removeLevel} from "../../../state/levels/actions";
import {appStorage} from "../../../services/AppStorage";
import {setSoundsEnabled} from "../../../state/ui/actions";
import {slideForwardTransition, slideFromBottomTransition, slideFromTopTransition} from "../../../Transitions";

interface LevelsListScreenProps {
    history: History,
    match: match,
    levels: Level[];
    tasks: Task[];
    addLevel: (level: Level) => void,
    removeLevel: (level_id: number) => void,
    setStoreSoundsEnabled: (enabled: boolean) => void,
}

function ParentLevelsListScreen({levels, tasks, history, addLevel, setStoreSoundsEnabled}: LevelsListScreenProps) {

    const [soundsEnabled, setSoundsEnabled] = React.useState(true);
    React.useEffect(() => {
        appStorage.getSettings().then(settings => {
            console.log({settings});
            setSoundsEnabled(settings.soundsEnabled);
        });
    }, []);

    const levelClicked = (level: Level) => {
        slideForwardTransition();
        history.replace(`/parent/level/${level.id}`)
    };

    const leaveParentMode = () => {
        slideFromBottomTransition();
        history.replace('/levels');
    }

    const showAbout = () => {
        slideFromTopTransition();
        history.replace('/parent/about');
    }

    const goToSettings = () => {
        slideFromTopTransition();
        history.replace('/parent/settings');
    }

    const createLevel = (level: Level) => {
        addLevel(level);
        slideForwardTransition();
        history.replace(`/parent/level/${level.id}`);
    }

    const toggleVolume = () => {
        appStorage.updateSettings({soundsEnabled: !soundsEnabled});
        setSoundsEnabled(!soundsEnabled);
        setStoreSoundsEnabled(!soundsEnabled);
    }


    return (
        <IonPage>
            <IonContent className="parent">
                <LevelListComponent
                    onCloseParentMode={() => leaveParentMode()}
                    onVolume={() => toggleVolume()}
                    onSettings={() => goToSettings()}
                    onShowAbout={() => showAbout()}
                    parentMode={true}
                    onCreateLevel={(level) => createLevel(level)}
                    onClick={levelClicked}
                    tasks={tasks}
                    levels={levels}
                    soundsEnabled={soundsEnabled}
                />
            </IonContent>
        </IonPage>
    );
}

const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
};
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addLevel,
        removeLevel,
        setStoreSoundsEnabled: setSoundsEnabled,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(ParentLevelsListScreen);
