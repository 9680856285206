import * as React from "react";
import {ConfettiSequence} from "../Sequences/Sequences";


export default class TimedOut extends React.Component<{onClick: Function}, any> {

    private timeout?: NodeJS.Timeout;

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.props.onClick();
        }, 3000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout!);
    }

    render() {
        return (
            <div className="wrapper" onClick={() => this.props.onClick()}>
                <div className="content">
                    <div className="container">
                        <div className="animation-wrapper confetti-animation-wrapper">
                            <div className="animation-text">Пора продолжить обучение</div>

                            <div className="animation-img">
                                <ConfettiSequence onDone={() => {}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
