import * as React from 'react';
import {Level} from "../../models/level";
import {Task} from "../../models/task";
import {GameHeaderComponent} from "./GameHeaderComponent";
import GameCardComponent from "./GameCardComponent";
import {CharacterToChoose, GameState} from "./types";
import {GameInputComponent} from "./GameInputComponent";
import {ThumbsUpSequence} from "../Sequences/Sequences";

export interface GameComponentProps {
    onComplete: Function;
    goBack: Function;
    onFail: Function;
    onSkipAnimation: Function;
    level: Level;
    task: Task;
    levelTasks: Task[];
    tasks: Task[];
    noHeaders?: boolean;
}

export interface GameComponentState {
    gameState: GameState;
    charactersToChoose: CharacterToChoose[];
    inputWord: string;
    failsCount: number;
}

export default class GameComponent extends React.Component<GameComponentProps, GameComponentState> {

    constructor(props: GameComponentProps) {
        super(props);
        this.state = {
            gameState: 'default',
            charactersToChoose: props.task.charactersToChoose,
            inputWord: '',
            failsCount: 0,
        };
    }

    componentDidUpdate(prevProps: Readonly<GameComponentProps>, prevState: Readonly<GameComponentState>, snapshot?: any) {
        if (this.props.task.id != prevProps.task.id) {
            this.setState(prev => ({...prev,
                gameState: 'default',
                charactersToChoose: this.props.task.charactersToChoose,
                inputWord: '',
                failsCount: 0,
            }));
        }
    }


    private footerRender() {
        if (this.state.gameState == 'success') {
            return (
                <div className="image-good">
                    <ThumbsUpSequence />
                </div>
            );
        } else {
            return (
                <GameInputComponent
                    task={this.props.task}
                    charactersToChoose={this.state.charactersToChoose}
                    charClicked={(char) => this.charClicked(char)}/>
            );
        }
    }

    private renewGameStateTimeout?: NodeJS.Timeout;

    private fail() {

        if (this.state.failsCount + 1 == 3) {
            this.props.onFail();
        }

        this.setState(prev => {
            return {
                ...prev,
                failsCount: prev.failsCount + 1,
                gameState: 'fail',
                inputWord: '',
            }
        });

        if (this.renewGameStateTimeout) {
            clearTimeout(this.renewGameStateTimeout);
        }
        this.renewGameStateTimeout = setTimeout(() => {
            this.setState(prev => {
                return {...prev, gameState: 'default'};
            });
        }, 2000);
    }

    private charClicked(char: CharacterToChoose) {
        if (!char.is_correct || !this.isNextCharCorrect(char)) {
            return this.fail();
        }

        if (this.props.task.type == Task.TYPE_WRITE_WORD) {
            this.handleCorrectCharWordType(char);
        } else {
            this.handleCorrectChar(char);
        }
    }

    private handleCorrectCharWordType(char: CharacterToChoose) {
        const newWord = this.state.inputWord + char.char;
        let newGameState = this.state.gameState;
        if (newWord.toLowerCase() == this.props.task.word.toLowerCase()) {
            newGameState = 'success';
        }
        this.setState(prev => {
            return {
                ...prev,
                inputWord: prev.inputWord + char.char,
                gameState: newGameState,
                failsCount: newGameState == 'success' ? 0 : prev.failsCount,
            };
        });

        if (newGameState == 'success') {
            this.onComplete();
        }
    }

    private handleCorrectChar(char: CharacterToChoose) {
        this.setState(prev => {
            return {
                ...prev,
                gameState: 'success',
            }
        });
        this.onComplete();
    }

    private clearFailsCount() {
        this.setState(prev => ({...prev, failsCount: 0}));
    }

    private onComplete() {
        this.clearFailsCount();
        this.props.onComplete();
    }

    private isNextCharCorrect(char: CharacterToChoose) {
        if (this.props.task.type != Task.TYPE_WRITE_WORD) {
            return true;
        }

        const currentInput = this.state.inputWord.split('');
        const word = this.props.task.word.split('');
        const expectedChar = word[currentInput.length].toLowerCase();

        return char.char.toLowerCase() == expectedChar.toLowerCase();
    }


    render() {
        const totalTasksCompleted = this.props.tasks.filter(t => t.done).length;
        return (
            <div className="wrapper">
                {this.props.noHeaders === true ? null : <GameHeaderComponent levelTasks={this.props.levelTasks}
                                     goBack={this.props.goBack}
                                     totalTasksCompleted={totalTasksCompleted}/>}
                <div className="content">
                    <div className="container">
                        <GameCardComponent
                            gameComponentState={this.state}
                            task={this.props.task}
                            inputWord={this.state.inputWord}
                            onClickSuccess={this.props.onSkipAnimation}
                        />
                        {this.footerRender()}
                    </div>
                </div>
            </div>
        );
    }
}

