import * as React from 'react';
import {CSSProperties} from "react";

interface SequenceAnimationProps {
    images: any[];
    autoplay: boolean;
    loop: boolean;
    fps: number;
    onDone: Function;
}

interface SequenceAnimationState {
    frame: number;
    interval: NodeJS.Timeout | null;
}

export default class SequenceAnimation extends React.Component<SequenceAnimationProps, SequenceAnimationState> {

    constructor(props: SequenceAnimationProps) {
        super(props);
        this.state = {
            frame: 0,
            interval: null,
        };
    }

    private startInterval() {
        this.stopInterval();

        let intervalTime =  Math.floor(1000/this.props.fps);
        const interval = setInterval(() => {
            this.nextFrame();
        }, Math.floor(1000/this.props.fps));

        this.setState(prev => ({...prev, interval}));
    }

    private stopInterval() {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
        this.setState(prev => ({...prev, interval: null}));
    }

    private nextFrame() {
        const totalFrames = this.props.images.length;
        const currentFrame = this.state.frame;
        let nextFrame = currentFrame + 1;

        if (currentFrame >= (totalFrames - 1)) {
            if (!this.props.loop) {
                this.stopInterval();
                this.props.onDone();
                return;
            }

            nextFrame = 0;
        }

        this.setState(prev => ({...prev, frame: nextFrame}));
    }

    componentDidMount() {
        if (this.props.autoplay) {
            this.startInterval();
        }
    }

    componentWillUnmount() {
        this.stopInterval();
    }


    render() {
        return (
            <div className="sequence-animation">
                {this.renderImages()}
            </div>
        );
    }

    private renderImages() {
        let result = [];
        for (let i in this.props.images) {
            if (!this.props.images.hasOwnProperty(i)) {
                continue;
            }
            const image = this.props.images[i];
            const isVisible = i == this.state.frame.toString();

            const visibleStyle: CSSProperties = {
                display: 'block',
            };
            const invisibleStyle: CSSProperties = {
                display: 'none',
            };

            let style = visibleStyle;
            if (!isVisible) {
                style = invisibleStyle;
            }

            result.push(<img src={image} key={i} alt="" style={style} />);
        }
        return result;
    }
}
