import React, {ElementRef} from 'react';
import {Task, TaskType} from "../../models/task";

interface ParentTaskEditProps {
    task: Task,
    onChange: (changes: Partial<Task>) => void,
    onSelectPhoto: Function,
    onDelete: Function,
    onSetTaskHiddenCharPos: (task: Task, hiddenCharIndex: number) => void,
    onScrollTo: (el: ElementRef<any>) => void,
}

export default function ({task, onChange, onSetTaskHiddenCharPos, onSelectPhoto, onDelete, onScrollTo}: ParentTaskEditProps) {

    const [shownWordInput, setShowWordInput] = React.useState(task?.word?.trim().length <= 0);
    const [charsBoxEnabled, setCharsBoxEnabled] = React.useState(task.chars_box.length > 0);

    if (!task) {
        return null;
    }

    const typesMap = [
        Task.TYPE_FIND_SAME_CHAR,
        Task.TYPE_FIND_CHAR,
        Task.TYPE_WRITE_WORD,
    ] as TaskType[];

    const difficultiesMap = {
        [Task.TYPE_FIND_SAME_CHAR]: 3,
        [Task.TYPE_FIND_CHAR]: 4,
        [Task.TYPE_WRITE_WORD]: 5,
    }

    const onWordChange = (newWord: string) => {
        onChange({word: newWord.trim().slice(0, 4).trim()});
    }

    const setCardDifficulty = (newDifficulty: number) => {
        onChange({difficulty: newDifficulty});
    }

    const setCardType = (newType: TaskType) => {
        let difficulty = task.difficulty;
        let maxDifficulty = difficultiesMap[newType];
        if (difficulty > maxDifficulty) {
            difficulty = maxDifficulty;
        }
        onChange({type: newType, difficulty});
    }

    const renderCardTypes = () => typesMap.map((type, i) => {
        const typeNumber = i + 1;
        const className = 'list-variable__item' + (task.type == type ? ' active' : '');
        return (
            <div className={className}
                 onClick={() => setCardType(typesMap[i])}
                 key={typeNumber}>
                {typeNumber}
            </div>
        );
    });

    const renderDifficulties = () => {
        let difficulties = Array.from({length: difficultiesMap[task.type]}, (_, i) => i + 1);

        return difficulties.map((difficulty, i) => {
            const className = 'list-variable__item' + (task.difficulty == difficulty ? ' active' : '');
            return (
                <div className={className}
                     onClick={() => setCardDifficulty(difficulty)}
                     key={difficulty}>
                    {difficulty}
                </div>
            );
        });
    }


    const renderImage = () => {
        if (task.imageSrc == Task.NO_IMAGE) {
            return (
                <div onClick={() => onSelectPhoto()} className="image-wrapper">
                    <img src="/assets/images/camera.svg" alt=""/>
                </div>
            );
        } else {
            return <img src={task.imageSrc} alt="" onClick={() => onSelectPhoto()}/>;
        }
    }

    const renderWord = () => {
        if (shownWordInput) {
            return (
                <label>
                    <input type="text"
                           value={task.word}
                           placeholder="Слово из 3-4х букв"
                           onChange={e => onWordChange(e.target.value)}
                           onFocus={e => onScrollTo(e.target)}
                           name="word_card"/>
                    <span className="icon-view" onClick={() => setShowWordInput(false)}>
                        <img src="/assets/images/view.svg" alt=""/>
                    </span>
                </label>
            );
        } else {
            let chars = task.wordWithHiddenChars().split('').map(c => c.trim()).filter(c => !!c);
            if (chars.length == 1 && chars[0] == '') {
                chars = [];
            }
            const renderChars = () => chars.map((char, i) => (
                <div className={`card-product-word__box ${char == Task.HIDDEN_CHAR ? 'color-red' : ''}`}
                     onClick={() => onSetTaskHiddenCharPos(task, i)}
                     key={i}>
                    {char}
                </div>
            ));
            return (
                <div className="card-settings__body-line">
                    <div className="variable-letter">
                        {renderChars()}
                    </div>
                    <span className="icon-view" onClick={() => setShowWordInput(true)}>
                        <img src="/assets/images/noview.svg" alt=""/>
                    </span>
                </div>
            );
        }
    }


    const renderCharsBox = () => {

        let charsBox;
        const updateCharsBox = (value: string) => {
            onChange({chars_box: value});
        };

        if (charsBoxEnabled) {
            charsBox = (
                <label>
                    <input type="text"
                           value={task.chars_box.trim()}
                           onChange={e => updateCharsBox(e.target.value)}
                           onFocus={e => onScrollTo(e.target)}
                           name="word_card"/>
                </label>
            );
        } else {
            charsBox = (
                <div className="variable-letter variable-letter-disable">
                    {task.charactersToChoose.map(c => c.char).map(c => c.trim()).filter(c => !!c).map((c, i) => {
                        return <div className="card-product-word__box" key={i}>{c}</div>
                    })}
                </div>
            );
        }

        return (
            <div className="card-settings__item">
                <div className="card-settings__line">
                    <div className="card-settings__item-icon">
                        <img src="/assets/images/letters.svg" alt=""/>
                    </div>
                    <p>{charsBoxEnabled ? 'Своя касса букв' : 'Случайная касса букв'}</p>
                </div>

                <div className="line-wrapper">

                    {charsBox}

                    <label className="checkbox">
                        <input className="checkbox-inp"
                               type="checkbox"
                               name="checkbox"
                               checked={charsBoxEnabled}
                               onChange={() => {
                                   onChange({chars_box: ''});
                                   setCharsBoxEnabled(!charsBoxEnabled)
                               }}
                        />
                        <span className="checkbox-custom"/>
                    </label>
                </div>
            </div>
        );
    }

    let cardSettingsClassName = `card-settings ${task.imageSrc == Task.NO_IMAGE ? '' : 'card-settings-two'}`;
    return (
        <div className={cardSettingsClassName}>
            <div className="card-settings__photo">
                {renderImage()}

                <a onClick={() => onDelete()} className="btn-delete">
                    <img src="/assets/images/delete.svg" alt=""/>
                </a>
            </div>
            <div className="card-settings__body">
                {renderWord()}
                <div className="card-settings__item">
                    <div className="card-settings__line">
                        <div className="card-settings__item-icon"><img src="/assets/images/card.svg" alt=""/></div>
                        <p>тип карточки</p>
                    </div>

                    <div className="list-variable list-variable-type">
                        {renderCardTypes()}
                    </div>
                </div>

                <div className="card-settings__item">
                    <div className="card-settings__line">
                        <div className="card-settings__item-icon"><img src="/assets/images/diffcuit.svg" alt=""/></div>
                        <p>сложность</p>
                    </div>

                    <div className="list-variable">
                        {renderDifficulties()}
                    </div>
                </div>

                {renderCharsBox()}
            </div>

        </div>
    );
}
