import React from 'react';

type YoutubeCheckerProps = {
    youtubeUrl: string,
    onStateChange: (result: YOUTUBE_CHECK_STATE) => void,
};

type YoutubeCheckerState = {
    videoChecked: boolean;
}


export const YOUTUBE_CHECK_STATE_IN_PROGRESS = 'IN_PROGRESS';
export const YOUTUBE_CHECK_STATE_WRONG_URL = 'WRONG_URL';
export const YOUTUBE_CHECK_STATE_SUCCESS = 'SUCCESS';
export const YOUTUBE_CHECK_STATE_FAIL = 'FAIL';
export type YOUTUBE_CHECK_STATE = 'IN_PROGRESS' | 'WRONG_URL' | 'SUCCESS' | 'FAIL';


export default class YoutubeChecker extends React.Component<YoutubeCheckerProps, YoutubeCheckerState> {

    private player: any;
    private tag: HTMLScriptElement | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            videoChecked: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<YoutubeCheckerProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.youtubeUrl != prevProps.youtubeUrl) {
            this.setState(prev => ({...prev, videoChecked: false}));
            this.updateVideoId();
        }
    }

    componentWillUnmount() {
        this.player?.destroy();
        this.tag?.remove();
        (window as any).onYoutubeIframeAPIReady = undefined;
        (window as any).YT = undefined;
    }

    componentDidMount() {
        this.props.onStateChange(YOUTUBE_CHECK_STATE_IN_PROGRESS);
        let tag = this.tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
        let resolveYT: Function;

        const YTReady = new Promise(resolve => {
            resolveYT = resolve;
        });
        (window as any).onYouTubeIframeAPIReady = function () {
            resolveYT();
        }

        YTReady.then(async () => {
            const YT = (window as any).YT;
            const videoId = this.getVideoId();

            if (!videoId) {
                return;
            }

            this.player = new YT.Player('player', {
                height: '100%',
                width: '100%',
                playerVars: {
                    'fs': '0',
                    'controls': '0',
                    playsinline: '1',
                    showinfo: '0',
                    autohide: '1',
                    mute: '1',
                    start: '1',
                },
                videoId: videoId,
                events: {
                    'onReady': this.onPlayerReady,
                    'onStateChange': (event: any) => {
                        if (event.data == YT.PlayerState.PLAYING) {
                            if (!this.state.videoChecked) {
                                this.setState(prev => ({...prev, videoChecked: true}));
                                this.props.onStateChange(YOUTUBE_CHECK_STATE_SUCCESS);
                                setTimeout(() => {
                                    this.stopVideo(event.target);
                                }, 100);
                            }
                        }
                    },
                    'onError': () => {
                        this.props.onStateChange(YOUTUBE_CHECK_STATE_FAIL);
                    },
                }
            });
        });
    }

    onPlayerReady(event: any) {
        event.target.playVideo();
    }

    stopVideo(player: any) {
        player && player.stopVideo();
    }

    getVideoId() {
        let originalUrl = this.props.youtubeUrl;
        let videoId;
        if (originalUrl.match('youtu.be')) {
            videoId = originalUrl.replace(/.+youtu\.be\/([a-zA-Z0-9_-]+).*/, '$1');
        } else if (originalUrl.match('youtube.com')) {
            videoId = originalUrl.replace(/.+v=([a-zA-Z0-9_-]+).*/, '$1');
        } else {
            videoId = '';
        }

        if (!videoId) {
            this.props.onStateChange(YOUTUBE_CHECK_STATE_WRONG_URL);
        }

        return videoId;
    }

    updateVideoId() {
        this.props.onStateChange(YOUTUBE_CHECK_STATE_IN_PROGRESS);
        let videoId = this.getVideoId();

        if (!this.player) {
            return;
        }

        if (!videoId) {
            return;
        }
        this.player.loadVideoById(videoId);
    }

    render() {
        return (
            <div id="player" />
        );
    }
}
