import React from 'react';
import {connect} from "react-redux";
import {Level} from "../../models/level";
import YoutubeRewardComponent from "../../components/Rewards/YoutubeRewardComponent";
import {IonContent} from "@ionic/react";
import {BasePageProps} from "../../types";
import {bindActionCreators} from "redux";
import {playLetsWorkSound, playLevelOpenedSound} from "../../state/ui/actions";
import CrossGestureHandler from "../../components/CrossGestureHandler/CrossGestureHandler";
import {appStorage} from "../../services/AppStorage";
import {Settings} from "../../models/settings";
import BubblesRewardComponent from "../../components/Rewards/BubblesRewardComponent";

interface RewardScreenProps extends BasePageProps<{ level_id: string }> {
    levels: Level[],
    playLevelOpenedSound: Function;
    playLetsWorkSound: Function;
}

interface RewardScreenState {
    settings: Settings;
}

class RewardScreen extends React.Component<RewardScreenProps, RewardScreenState> {

    constructor(props: RewardScreenProps) {
        super(props);
        this.state = {
            settings: {} as Settings,
        };

        appStorage.getSettings().then(settings => {
            this.setState(prev => ({...prev, settings}));
        })
    }


    componentDidMount() {
        this.props.playLevelOpenedSound();
    }

    rewardCompleted() {
        const level = this.props.levels.find(l => l.id == Number(this.props.match.params.level_id));
        if (level) {
            this.props.history.push(`/level/${level.id}/next-task`);
        }
    }


    private goToParentMode() {
        this.props.history.replace('/parent/levels');
    }

    private setNewPassword(password: string) {
        appStorage.setParentPassword(password);
    }

    render() {
        const level = this.props.levels.find(l => l.id == Number(this.props.match.params.level_id));

        const renderContent = () => {
            if (!level) {
                console.log('no level');
                return null;
            }
            switch (level.reward_type) {
                case Level.REWARD_YOUTUBE:
                    return (
                        <YoutubeRewardComponent
                            onTimedOut={() => this.props.playLetsWorkSound()}
                            onCompleted={() => this.rewardCompleted()}
                            youtubeUrl={level.reward_youtube_url}
                            localUrl={level.local_video_path}
                            timeoutSeconds={level.reward_youtube_duration_secs || 120}
                        />
                    );
                case Level.REWARD_BUBBLES:
                    return (
                        <BubblesRewardComponent
                            onTimedOut={() => this.props.playLetsWorkSound()}
                            onCompleted={() => this.rewardCompleted()}
                            animationNumber={level.reward_bubble_type}
                            timeoutSeconds={level.reward_youtube_duration_secs || 120}
                        />
                    );
                default:
                    return null;
            }
        }

        return (
            <IonContent forceOverscroll={false}>
                {renderContent()}
                <CrossGestureHandler
                    onGoToParentMode={() => this.goToParentMode()}
                    onSetParentPassword={(password) => this.setNewPassword(password)}
                    settings={this.state.settings}/>
            </IonContent>
        );
    }

}


const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
};
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        playLevelOpenedSound,
        playLetsWorkSound,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(RewardScreen);
