import {Level} from "../../models/level";

export const ADD_LEVELS_ACTION = 'ADD_LEVELS';
export const ADD_LEVEL_ACTION = 'ADD_LEVEL';
export const DROP_PROGRESS_ACTION = 'DROP_PROGRESS';
export const UPDATE_LEVEL_ACTION = 'UPDATE_LEVEL';
export const REMOVE_LEVEL_ACTION = 'REMOVE_LEVEL';

export const addLevels = (levels: Level[]) => ({
    type: ADD_LEVELS_ACTION,
    levels
});

export const dropProgress = (level: Level) => ({
    type: DROP_PROGRESS_ACTION,
    level
});

export const updateLevel = (level: Level) => ({
    type: UPDATE_LEVEL_ACTION,
    level
});

export const addLevel = (level: Level) => ({
    type: ADD_LEVEL_ACTION,
    level
});

export const removeLevel = (level_id: number) => ({
    type: REMOVE_LEVEL_ACTION,
    level_id,
});
