import React, {CSSProperties, useRef, useState} from 'react';
import {Task} from "../../models/task";
import {Level} from "../../models/level";
import {DropTargetMonitor, useDrag, useDrop, XYCoord} from "react-dnd";

interface ParentTaskItemProps {
    task: Task;
    level: Level;
    onTaskClick: Function;
    moveTask: (dragIndex: number, hoverIndex: number) => void,
    index: number,
    style: CSSProperties,
}

interface DragItem {
    index: number
    id: string
    type: string
}

export default function ParentTaskItem({task, onTaskClick, index, moveTask, style}: ParentTaskItemProps) {

    const [showWord, setShowWord] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: 'CARD',
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleYBottom =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverMiddleYTop =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2


            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleYBottom) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleYTop) {
                return
            }

            // Time to actually perform the action
            moveTask(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{isDragging}, drag, preview] = useDrag({
        item: {type: 'CARD', id: task.id, index},
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))

    const renderChars = () => {
        const word = showWord ? task.word : task.wordWithHiddenChars();
        return word.split('').map((char, i) => {
            return <div className="card-line__name-text-box" key={i}>{char}</div>;
        });
    }

    const taskTypeNumber = () => {
        return {
            [Task.TYPE_FIND_SAME_CHAR]: 1,
            [Task.TYPE_FIND_CHAR]: 2,
            [Task.TYPE_WRITE_WORD]: 3
        }[task.type];
    }

    const starImage = task.done ? "/assets/images/star-card.svg" : "/assets/images/star-card2.svg";

    const renderCard = () => {
        return (
            <div className={"card-line" + (isDragging ? ' dragging' : '')}
                 style={{...style}}
                 ref={ref}
                 onClick={() => isDragging || onTaskClick()}
            >
                <div className="card-line-characteristics">
                    <div className="rating">
                        <img src={starImage} alt=""/>
                    </div>
                    <div className="card-val"><img src="/assets/images/card.svg" alt=""/><p>{taskTypeNumber()}</p></div>
                    <div className="card-val"><img src="/assets/images/dif.svg" alt=""/><p>{task.difficulty}</p></div>
                </div>
                <div className="card-line__img"><img src={task.imageSrc} alt=""/></div>

                <div className="card-line__name">
                    <h4>{task.word}</h4>
                    <div className="card-line__name-text">
                        {renderChars()}
                    </div>
                </div>

                <a className="btn-view"
                   style={{width: '20px', height: '20px'}}
                   onClick={(e) => {setShowWord(!showWord); e.stopPropagation();}}>
                    <img src={showWord ? "/assets/images/view.svg" : "/assets/images/noview.svg"} alt=""/>
                </a>
            </div>
        );
    };

    const renderLine = () => {
        return <div className="line-border"/>;
    };

    return (
      <>
          {renderCard()}
          {/*{isDragging ? renderLine() : renderCard()}*/}
      </>
    );
}
