import React from 'react';
import {Task} from "../../models/task";
import {BACKEND_URL} from "../../constants";
import {connect} from "react-redux";
import {Level} from "../../models/level";


function ImagesPreload({tasks, levels}: { tasks: Task[], levels: Level[] }) {

    const images = tasks
        .filter(i => !i.img_local_path && i.img_external_path || i.img_path)
        .filter(task => {
            const level = levels.find(l => l.id == task.level_id);
            if (!level) {
                return false;
            }
            const isLevelOpened = level.completed_tasks_required <= tasks.filter(t => t.done).length;
            return isLevelOpened;
        })
        .map((t, i) => {
            const externalPath = t.img_external_path;
            const apiPath = BACKEND_URL + t.img_path;

            const src = externalPath || apiPath;
            return <img src={(src)} key={t.id}/>
        });

    return (
        <div className="images-preload">
            {images}
        </div>
    );
}

const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
};
export default connect(mapStateToProps)(ImagesPreload);
