import * as React from 'react';
import {Level} from "../../models/level";
import Digit from "./Digit";
import {Task} from "../../models/task";

interface LevelItemComponentProps {
    level: Level,
    tasks: Task[],
    onClick: () => void,
    completionPercents: number,
    opened: boolean,
    isNext: boolean,
    parentMode: boolean,
}

export function LevelItemComponent({level, tasks, onClick, completionPercents, opened, isNext, parentMode}: LevelItemComponentProps) {

    const getLevelDigits = () => {
        let digits = [];
        let levelNumber = level.level_number;

        while (levelNumber >= 10) {
            digits.push(levelNumber % 10);
            levelNumber = Math.floor(levelNumber / 10);
        }
        digits.push(levelNumber);
        digits.reverse();
        return digits;
    };

    const filteredTasks = tasks
        .filter(task => task.word.trim().length > 0)
        .sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    const renderStars = () => (
        <ul className="list-stars">
            {(parentMode ? tasks : filteredTasks).map(t => t.done)
                .map((done, i) => (
                    done ? <li key={i}><img src="/assets/images/star.svg" alt=""/></li> :
                        <li key={i}><img src="/assets/images/star-empty.svg" alt=""/></li>
                ))}
        </ul>
    );

    const renderLevelNumber = () =>
        getLevelDigits().map((digit, idx) => (opened || parentMode) ?
            (<Digit key={`${idx}-${digit}-${completionPercents}`} fillLevel={completionPercents} digit={digit}/>)
            : (<img key={`${idx}`} src={`/assets/images/digits/${digit}-dotted.svg`}
                    className={`level-digit-${digit} level-digit`} alt=""/>));

    const renderBottom = () => {
        if (opened || parentMode) {
            return renderStars();
        } else {
            return [
                (<div className="level-score" key={0}>{level.completed_tasks_required}</div>),
                (<div className="tooltip-wrapper" key={1}>
                    <div className="lock-icon"><img src="/assets/images/lock.svg" alt=""/></div>
                    <div className="tooltip-box">Чтобы открыть уровень набери больше звезд</div>
                </div>)
            ];
        }
    };

    let className = "level-box";
    if (isNext) {
        className += " level-box-next";
    }

    let levelBoxNumberClassName = "level-box__number";
    const digitsCount = getLevelDigits().length;
    levelBoxNumberClassName += ` level-box__number-${digitsCount}-digits`;

    return (
        <div className={className} onClick={onClick}>
            <div className={levelBoxNumberClassName}>
                {renderLevelNumber()}
            </div>
            {renderBottom()}
        </div>
    );
}
