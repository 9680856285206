import * as React from 'react';
import {RefObject} from 'react';
import {bindActionCreators} from "redux";
import {addTask, updateTask} from "../../../state/tasks/actions";
import {dropProgress, removeLevel, updateLevel} from "../../../state/levels/actions";
import {connect} from "react-redux";
import {IonContent, IonPage} from "@ionic/react";
import ParentTaskList from "../../../components/ParentTaskList/ParentTaskList";
import {BasePageProps} from "../../../types";
import {Level} from "../../../models/level";
import {Task} from "../../../models/task";
import {slideForwardTransition} from "../../../Transitions";


interface ParentLevelsListScreenProps extends BasePageProps<{ level_id?: string }> {
    levels: Level[],
    tasks: Task[],
    updateLevel: (level: Level) => void,
    updateTask: (task: Task) => void,
    dropProgress: (level: Level) => void,
    removeLevel: (level_id: number) => void,
    addTask: (task: Task) => void,
}

function ParentLevelEditScreen({history, match, levels, tasks, dropProgress, updateLevel, updateTask, addTask, removeLevel}: ParentLevelsListScreenProps) {

    const [deleteModalLevel, setLevelForDeleteModal] = React.useState<Level | null>(null);
    const [dropProgressModalVisible, setDropProgressModalVisible] = React.useState(false);


    const ionContentRef = React.useRef<HTMLIonContentElement>() as RefObject<HTMLIonContentElement>;

    const getLevel = () => {
        return levels.find(l => l.id == match.params.level_id);
    }

    const getLevelTasks = () => {
        return tasks.filter(t => t.level_id == getLevel()?.id)
    }

    const goBack = () => {
        // slideBackTransition();
        history.replace(`/parent/levels`)
    }

    if (!getLevel()) {
        return null;
    }

    const onLevelChange = (changes: Partial<Level>) => {
        const level = new Level({...getLevel(), ...changes});
        updateLevel(level);
    }

    const goToTask = (task: Task) => {
        slideForwardTransition();
        history.replace(`/parent/task/${task.id}`)
    }

    const swapTasks = (a: number, b: number) => {
        const taskA = tasks.find(t => t.id == a);
        const taskB = tasks.find(t => t.id == b);
        if (!taskA || !taskB) {
            return;
        }
        const taskAClone = Object.assign(new Task(taskA), taskA);
        const taskBClone = Object.assign(new Task(taskB), taskB);
        [taskAClone.sort_order, taskBClone.sort_order] = [taskB.sort_order, taskA.sort_order];
        updateTask(taskAClone);
        updateTask(taskBClone);
    }


    const addCard = () => {
        const level = getLevel()!;
        const newId = -(Math.max(...tasks.map(t => Math.abs(t.id!))) + 1);
        const newSortOrder = Math.max(...tasks.filter(t => t.level_id == level.id).map(t => t.sort_order)) + 1;
        const task = new Task({
            id: newId,
            level_id: level.id,
            difficulty: 1,
            type: Task.TYPE_FIND_SAME_CHAR,
            done: false,
            sort_order: newSortOrder,
        });
        addTask(task);
        history.replace(`/parent/task/${task.id}`);
    }

    const renderDropProgressModal = () => {
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!dropProgressModalVisible) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        return (
            <div className={dropProgressModalVisible ? '' : 'hidden'}
                 onClick={() => setDropProgressModalVisible(false)}>
                <div className={overlayClass}/>
                <div className={modalClass}>
                    <div className="modal-title">Сброс прогресса уровня</div>
                    <div className="box-text">
                        <p>При сбросе прогресса все результаты прохождения в этом уровне будут безвозвратно удалены.</p>
                        <p>Вы уверены, что хотите удалить прогресс в этом уровне?</p>
                    </div>

                    <div className="button-group button-group-row">
                        <a className="btn btn-accent" onClick={() => {
                            dropProgress(getLevel()!);
                            setDropProgressModalVisible(false);
                        }}>
                            <span>Да</span>
                        </a>
                        <a className="btn btn-accent" onClick={() => setDropProgressModalVisible(false)}>
                            <span>Нет</span>
                        </a>
                    </div>

                </div>
            </div>
        );
    }

    const renderDeleteLevelModal = () => {
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!deleteModalLevel) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        return (
            <div className={!!deleteModalLevel ? '' : 'hidden'}
                 onClick={() => setLevelForDeleteModal(null)}>
                <div className={overlayClass}/>
                <div className={modalClass}>
                    <div className="modal-title">Удаление уровня</div>
                    <div className="box-text">
                        <p>При удалении уровня, все карточки внутри уровня будут удалены, а прогресс за этот уровень
                            сброшен.</p>
                        <p>Вы уверены, что хотите удалить уровень?</p>
                    </div>

                    <div className="button-group button-group-row">
                        <a className="btn btn-accent" onClick={() => {
                            removeLevel(deleteModalLevel!.id!);
                            setLevelForDeleteModal(null);
                            history.replace('/parent/levels');
                        }}>
                            <span>Да</span>
                        </a>
                        <a className="btn btn-accent" onClick={() => setLevelForDeleteModal(null)}>
                            <span>Нет</span>
                        </a>
                    </div>

                </div>
            </div>
        );
    }

    return (
        <IonPage>
            <IonContent className="parent" ref={ionContentRef} scrollEvents={true}>
                <ParentTaskList onGoBack={() => goBack()}
                                onGoToTask={task => goToTask(task)}
                                onAddCard={() => addCard()}
                                onDropProgress={() => setDropProgressModalVisible(true)}
                                onLevelChange={(changes: Partial<Level>) => onLevelChange(changes)}
                                onDeleteLevel={() => setLevelForDeleteModal(getLevel()!)}
                                onScrollTo={(elementRef: any) => {
                                    ionContentRef.current?.scrollToPoint(0, elementRef.offsetTop - ((document.body.clientHeight) / 6), 500);
                                }}
                                onSwap={(a, b) => swapTasks(a, b)}
                                level={getLevel()!}
                                levelTasks={getLevelTasks()}/>
                {renderDropProgressModal()}
                {renderDeleteLevelModal()}
            </IonContent>
        </IonPage>
    );
}

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        updateLevel,
        updateTask,
        addTask,
        dropProgress,
        removeLevel,
    }, dispatch)
);
const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentLevelEditScreen);
