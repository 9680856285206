import {Action} from "redux";
import {
    PLAY_LETS_WORK_SOUND_ACTION,
    PLAY_LEVEL_OPENED_SOUND_ACTION,
    PLAY_TASK_COMPLETED_SOUND_ACTION,
    SET_SOUNDS_ENABLED_ACTION
} from "./actions";
import {
    letsWorkBaseDir,
    letsWorkFiles,
    levelOpenedBaseDir,
    levelOpenedFiles,
    taskCompletedBaseDir,
    taskCompletedFiles
} from "./soundFiles";

const preloadedAudio = (file: string) => {
    const audio = new Audio(file);
    audio.preload = 'auto';
    return audio;
}

const INITIAL_STATE = {
    soundsEnabled: true,
    sounds: {
        levelOpened: levelOpenedFiles[0],
        taskCompleted: taskCompletedFiles[0],
        letsWork: letsWorkFiles[0],
    },
    preloadedAudio: {
        levelOpened: preloadedAudio(`${levelOpenedBaseDir}/${levelOpenedFiles[0]}`),
        taskCompleted: preloadedAudio(`${taskCompletedBaseDir}/${taskCompletedFiles[0]}`),
        letsWork: preloadedAudio(`${letsWorkBaseDir}/${letsWorkFiles[0]}`),
    },
}

type UiState = typeof INITIAL_STATE;

export const uiReducer = (state: UiState = INITIAL_STATE, action: Action): UiState => {

    switch (action.type) {
        case PLAY_TASK_COMPLETED_SOUND_ACTION:
            return playTaskCompleted(state);
        case PLAY_LETS_WORK_SOUND_ACTION:
            return playLetsWork(state);
        case PLAY_LEVEL_OPENED_SOUND_ACTION:
            return playLevelOpened(state);
        case SET_SOUNDS_ENABLED_ACTION:
            return {
                ...state,
                soundsEnabled: (action as any).enabled !== false,
            };
    }

    return state;
}


type playArguments = {
    files: string[],
    stateField: 'levelOpened'|'taskCompleted'|'letsWork',
    state: UiState,
    baseDir: string,
};

function play({files, stateField, state, baseDir}: playArguments) {
    if (state.soundsEnabled === false) {
        console.log('sounds disabled');
        return state;
    }
    let player = state.preloadedAudio[stateField];
    player.play().then(() => {
        player.remove();
    }).catch((err) => {
        console.error('cannot play', err);
    })

    const newSound = getNewSound(files, state.sounds[stateField]);
    return {
        ...state,
        sounds: {
            ...state.sounds,
            [stateField]: newSound,
        },
        preloadedAudio: {
            ...state.preloadedAudio,
            [stateField]: preloadedAudio(`${baseDir}/${newSound}`),
        }
    }
}

function getNewSound(files: string[], currentSound: string) {
    let newIndex = files.indexOf(currentSound) + 1;
    if (newIndex >= files.length) {
        newIndex = 0;
    }
    return files[newIndex];
}


function playTaskCompleted(state: UiState): UiState {
    return play({
        stateField: 'taskCompleted',
        baseDir: taskCompletedBaseDir,
        files: taskCompletedFiles,
        state,
    });
}


function playLetsWork(state: UiState): UiState {
    return play({
        stateField: 'letsWork',
        baseDir: letsWorkBaseDir,
        files: letsWorkFiles,
        state,
    });
}


function playLevelOpened(state: UiState): UiState {
    return play({
        stateField: 'levelOpened',
        baseDir: levelOpenedBaseDir,
        files: levelOpenedFiles,
        state,
    });
}

