import * as React from 'react';
import {Task} from "../../models/task";
import {TaskItemComponent} from "./TaskItemComponent";


type TaskListComponentProps = {
    tasks: Task[],
    onClick: (task: Task) => void,
    goBack: Function,
    completedTasksCount: number,
};
export function TaskListComponent({tasks, onClick, goBack, completedTasksCount}: TaskListComponentProps) {

    const onClickHandler = (task: Task) => {
        onClick(task);
    }

    const sortedTasks = [...tasks].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    [...sortedTasks].sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            console.error({a, b});
        }
        return 0;
    });
    const renderItems = () => sortedTasks.map((task, i) => (
        <TaskItemComponent key={task.id} index={i} task={task} onClick={() => onClickHandler(task)}/>
    ));

    return (
        <div className="wrapper">
            <div className="header">
                <div className="container">
                    <a onClick={() => goBack()} className="btn-back">
                        <span>
                            <img src={"/assets/images/arrow-left.svg"} alt=""/>
                        </span>
                    </a>

                    <div className="letter">
                        <div className="letter-icon">
                            <img src={"/assets/images/star-white.svg"} alt=""/>
                        </div>
                        <div className="letter-num">{completedTasksCount}</div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="card-wrapper">
                        {renderItems()}
                    </div>
                </div>
            </div>
        </div>
    );
}
