import React, {useEffect, useState} from 'react';
import {BasePageProps} from "../types";
import {IonContent} from "@ionic/react";
import GameComponent from "../components/Game/GameComponent";
import {Task} from "../models/task";
import {Level} from "../models/level";
import {CrossNoTextSequence} from "../components/Sequences/Sequences";
import {appStorage} from "../services/AppStorage";

interface OnboardingScreenProps extends BasePageProps<any> {
}

export default function OnboardingScreen(props: OnboardingScreenProps) {

    const [pageNumber, setPageNumber] = useState(1);
    useEffect(() => {
        appStorage.getSettings().then(settings => {
            if (settings.onboarded === true) {
                props.history.replace('/levels');
            }
        });
    }, []);

    const skip = () => {
        appStorage.updateSettings({onboarded: true});
        props.history.replace('/levels');
    };

    const next = () => {
        if (pageNumber >= 7) {
            appStorage.updateSettings({onboarded: true});
            props.history.replace('/levels');
        } else {
            setPageNumber(pageNumber + 1);
        }
    }

    const renderPage = () => {
        switch (pageNumber) {
            case 1:
                return <PageOne skip={skip} next={next}/>;
            case 2:
                return <PageTwo skip={skip} next={next}/>;
            case 3:
                return <PageThree skip={skip} next={next}/>;
            case 4:
                return <PageFour skip={skip} next={next}/>;
            case 5:
                return <PageFive skip={skip} next={next}/>;
            case 6:
                return <PageSix skip={skip} next={next}/>;
            case 7:
                return <PageSeven skip={skip} next={next}/>;
        }
    }

    return (
        <IonContent>
            {renderPage()}
        </IonContent>
    );
}


interface OnboardingPageProps {
    next: Function;
    skip: Function;
}

function PageOne({next, skip}: OnboardingPageProps) {
    return (
        <div className="wrapper onboarding-page onboarding-page-1">
            <div className="header">
                <div className="container">
                    <div className="filler"/>
                    <div className="links-skip" onClick={() => skip()}>Пропустить</div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <img src="/assets/images/logo.svg"/>

                    <section className="text-white text-h4">
                        Добро пожаловать.<br/>
                        Мы&nbsp;проведем небольшой ликбез по&nbsp;тому, как&nbsp;пользоваться приложением
                    </section>

                    <button type="submit" className="btn btn-accent" onClick={() => next()}>
                        <span>Начать</span>
                    </button>
                </div>
            </div>
        </div>
    );
}


function PageTwo({next, skip}: OnboardingPageProps) {
    return (
        <div className="wrapper onboarding-page onboarding-page-2">
            <div className="header">
                <div className="container">
                    <img src="/assets/images/logo.svg"/>
                    <div className="links-skip" onClick={() => skip()}>Пропустить</div>
                </div>
            </div>

            <div className="content">
                <div className="container">

                    <section className="text-white text-h5">
                        Тут дети учатся находить нужные буквы в тех словах,
                        которые они видят повсеместно. Попробуйте пройти пару простых заданий,
                        чтобы понять, как играть
                    </section>

                    <button type="submit" className="btn btn-accent" onClick={() => next()}>
                        <span>Попробовать</span>
                    </button>

                </div>
            </div>
        </div>
    );
}


function PageThree({next}: OnboardingPageProps) {
    const level = new Level({
        id: 1,
        completed_tasks_required: 100,
        level_number: 1,
    });

    const tasks = [
        new Task({
            id: 1,
            word: 'Дом',
            img_external_path: '/assets/images/card5.jpeg',
            level_id: 1,
        }),
    ];
    const task = tasks[0];
    return (
        <GameComponent
            onFail={() => {}}
            noHeaders={true}
            onComplete={() => setTimeout(() => next(), 1500)}
            goBack={() => next()}
            onSkipAnimation={() => {}}
            level={level}
            task={task}
            levelTasks={tasks}
            tasks={tasks}/>
    );
}


function PageFour({next, skip}: OnboardingPageProps) {
    return (
        <div className="wrapper onboarding-page onboarding-page-4">

            <div className="overlay">
                <div className="container">
                    <div className="filler"/>
                    <section className="text-white text-h5">
                        После того, как ребенок наберет нужное количество
                        баллов &mdash; ему будет доступно поощрение (небольшая игра или видео).
                    </section>

                    <section className="gift onboarding-illustration">
                        <img src="/assets/sequences/presentOpens/Comp 6_00007.png" alt=""/>
                        <section className="illustration-row">
                            <img src="/assets/images/star-1.svg" alt=""/>
                            <img src="/assets/images/star-1.svg" alt=""/>
                            <img src="/assets/images/star-1.svg" alt=""/>
                            <img src="/assets/images/star-1.svg" alt=""/>
                            <img src="/assets/images/star-1.svg" alt=""/>
                        </section>
                    </section>

                    <button type="submit" className="btn btn-accent" onClick={() => next()}>
                        <span>Далее</span>
                    </button>
                </div>
            </div>

            <div className="header">
                <div className="container">
                    <div className="timer-wrapper">
                        <div className="timer-box">
                            <span>0</span>
                            <span>1</span>
                        </div>
                        <div className="timer-dots">
                            <img src="/assets/images/dots.svg" alt=""/>
                        </div>
                        <div className="timer-box">
                            <span>5</span>
                            <span>9</span>
                        </div>
                    </div>
                    <div className="links-skip">Пропустить</div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="animation-wrapper">
                        <img src="/assets/images/bubble.svg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}


function PageFive({next, skip}: OnboardingPageProps) {

    return (
        <div className="wrapper onboarding-page onboarding-page-5">

            <div className="overlay">
                <div className="container">
                    <div className="filler"/>

                    <section className="text-white text-h5">
                        Вы можете настраивать практически всё:
                        редактировать карточки и уровни, поощрения,
                        никто не знает вашего ребенка, как знаете его вы.
                    </section>

                    <div className="onboarding-illustration">
                        <img src="/assets/images/settings.svg"/>
                        <br/>
                        <div className="illustration-row">
                            <img src="/assets/images/checkbox-checked.svg"/>
                            <img src="/assets/images/checkbox-checked.svg"/>
                            <img src="/assets/images/checkbox-unchecked.svg"/>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-accent" onClick={() => next()}>
                        <span>Далее</span>
                    </button>
                </div>
            </div>


            <div className="content">
                <div className="container">

                    <div className="card-line">
                        <div className="card-line-characteristics">
                            <div className="rating"><img src="/assets/images/star-card.svg" alt=""/></div>
                            <div className="card-val"><img src="/assets/images/card.svg" alt=""/><p>1</p></div>
                            <div className="card-val"><img src="/assets/images/dif.svg" alt=""/><p>3</p></div>
                        </div>
                        <div className="card-line__img"><img src="/assets/images/home.png" alt=""/></div>

                        <div className="card-line__name">
                            <h4>дом</h4>
                            <div className="card-line__name-text">
                                <div className="card-line__name-text-box">д</div>
                                <div className="card-line__name-text-box">•</div>
                                <div className="card-line__name-text-box">м</div>
                            </div>
                        </div>

                        <a className="btn-view"><img src="/assets/images/noview.svg" alt=""/></a>
                    </div>

                    <form method="post" className="form-password form-settings">
                        <div className="popup-box">
                            <div className="popup-box-title">поощрение</div>
                            <p className="description">Поощрение происходит при переходе на этот уровень</p>
                            <label className="radio">
                                <input className="radio-inp" type="radio" name="radio" checked/>
                                <span className="radio-custom"></span>
                                <span className="label">Игра в пузыри</span>
                            </label>
                            <label className="radio">
                                <input className="radio-inp" type="radio" name="radio"/>
                                <span className="radio-custom"></span>
                                <span className="label">Игра в шарики</span>
                            </label>
                            <label className="radio">
                                <input className="radio-inp" type="radio" name="radio"/>
                                <span className="radio-custom"></span>
                                <span className="label">Мультфильм</span>
                            </label>

                            <label>
                                <input type="text"
                                       placeholder="Ссылка на видео в YouTube или Vimeo"
                                       name="links"/>
                            </label>

                            <div className="line-wrapper">
                                <p>или загрузите с телефона</p>

                                <a className="btn-back"><span>
                                    <img src="/assets/images/subtract.svg" alt=""/></span>
                                </a>
                            </div>
                            <p className="description">Время переменки (мин)</p>
                            <label><input type="text" value="1" name="time"/></label>
                        </div>

                    </form>
                    <div className="button-group">
                        <a className="btn btn-border"><span>Сбросить прогресс</span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PageSix({skip, next}: OnboardingPageProps) {
    return (
        <div className="wrapper onboarding-page onboarding-page-6">
            <div className="overlay">
                <div className="container">
                    <div className="filler"/>

                    <section className="text-white text-h5">
                        Поменять фото карточки на свою &mdash;
                        например, вставить фото дедушки, и при правильных ответах &mdash;
                        включать любимый мультик.
                    </section>

                    <div className="onboarding-illustration">
                        <div className="illustration-row">
                            <img src="/assets/images/star-1.svg"/>
                            <img src="/assets/images/star-1.svg"/>
                            <img src="/assets/images/star-1.svg"/>
                        </div>
                        <br/>
                        <img src="/assets/images/onboarding-illustration-6.svg"/>
                    </div>

                    <button type="submit" className="btn btn-accent" onClick={() => next()}>
                        <span>Далее</span>
                    </button>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="card-settings card-settings-two">
                        <div className="card-settings__photo">
                            <img src="/assets/images/card9.png" alt=""/>

                                <a className="btn-delete"><img src="/assets/images/delete.svg" alt=""/></a>
                        </div>
                        <div className="card-settings__body">
                            <label><input type="text" value="ЧАСЫ" name="word_card" />
                                <span className="icon-view"><img src="/assets/images/view.svg" alt=""/></span>
                            </label>
                            <div className="card-settings__item">
                                <div className="card-settings__line">
                                    <div className="card-settings__item-icon"><img src="/assets/images/card.svg" alt=""/></div>
                                    <p>тип карточки</p>
                                </div>

                                <div className="list-variable list-variable-type">
                                    <div className="list-variable__item active">1</div>
                                    <div className="list-variable__item">2</div>
                                    <div className="list-variable__item">3</div>
                                </div>
                            </div>

                            <div className="card-settings__item">
                                <div className="card-settings__line">
                                    <div className="card-settings__item-icon"><img src="/assets/images/diffcuit.svg" alt=""/></div>
                                    <p>сложность</p>
                                </div>

                                <div className="list-variable">
                                    <div className="list-variable__item active">1</div>
                                    <div className="list-variable__item">2</div>
                                    <div className="list-variable__item">3</div>
                                    <div className="list-variable__item">4</div>
                                    <div className="list-variable__item">5</div>
                                </div>
                            </div>

                            <div className="card-settings__item">
                                <div className="card-settings__line">
                                    <div className="card-settings__item-icon"><img src="/assets/images/letters.svg" alt=""/></div>
                                    <p>касса букв</p>
                                </div>

                                <div className="line-wrapper">
                                    <div className="variable-letter variable-letter-disable">
                                        <div className="card-product-word__box">И</div>
                                        <div className="card-product-word__box">Т</div>
                                        <div className="card-product-word__box">Р</div>
                                    </div>

                                    <label className="checkbox">
                                        <input className="checkbox-inp" type="checkbox" name="checkbox" />
                                            <span className="checkbox-custom"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);
}


function PageSeven({next, skip}: OnboardingPageProps) {
    return (
        <div className="wrapper onboarding-page onboarding-page-7">
            <div className="overlay">
                <div className="container">
                    <section className="text-white text-h4">
                        Для входа в родительский режим
                    </section>

                    <CrossNoTextSequence/>

                    <button type="submit" className="btn btn-accent" onClick={() => next()}>
                        <span>Далее</span>
                    </button>
                </div>
            </div>


            <div className="content">
                <div className="container">
                    <div className="card-product">
                        <div className="card-product__box">
                            <div className="card-product-img"><img src="/assets/images/card6.png" alt=""/></div>
                            <div className="card-product-word">
                                <div className="row">
                                    <div className="card-product-word__box color-red">И</div>
                                    <div className="card-product-word__box">З</div>
                                    <div className="card-product-word__box">Ю</div>
                                    <div className="card-product-word__box">м</div>
                                </div>
                                <div className="row">
                                    <div className="card-product-word__box"></div>
                                    <div className="card-product-word__box">З</div>
                                    <div className="card-product-word__box">Ю</div>
                                    <div className="card-product-word__box">м</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="variable-letter">
                        <div className="card-product-word__box">н</div>
                        <div className="card-product-word__box">и</div>
                        <div className="card-product-word__box">а</div>
                        <div className="card-product-word__box">п</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
