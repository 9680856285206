import {Level, LevelRewardType} from "../../models/level";
import React, {ElementRef} from "react";
import YoutubeChecker, {
    YOUTUBE_CHECK_STATE,
    YOUTUBE_CHECK_STATE_FAIL,
    YOUTUBE_CHECK_STATE_IN_PROGRESS,
    YOUTUBE_CHECK_STATE_SUCCESS,
    YOUTUBE_CHECK_STATE_WRONG_URL
} from "./YoutubeChecker";
import {WebView} from "@ionic-native/ionic-webview";
import AnimationFour from "../Rewards/Bubbles/AnimationFour";
import AnimationOne from "../Rewards/Bubbles/AnimationOne";
import AnimationTwo from "../Rewards/Bubbles/AnimationTwo";
import {AnimationFive} from "../Rewards/Bubbles/AnimationFive";
import AnimationThree from "../Rewards/Bubbles/AnimationThree";
import {normalizeSrc} from "../../App";


interface LevelSettingsProps {
    level: Level;
    onDropProgress: Function;
    onChange: (changes: Partial<Level>) => void;
    onScrollTo: (el: ElementRef<any>) => void;
    onLoadVideo: Function;
}

export default function LevelSettings({level, onDropProgress, onChange, onLoadVideo, ...props}: LevelSettingsProps) {

    const [youtubeCheckState, setYoutubeCheckState] = React.useState(YOUTUBE_CHECK_STATE_IN_PROGRESS as YOUTUBE_CHECK_STATE);
    const isBubbles = level.reward_type == Level.REWARD_BUBBLES;
    const isYoutube = level.reward_type == Level.REWARD_YOUTUBE;

    const setReward = (reward_type: LevelRewardType) => {
        onChange({reward_type});
    }

    const setBubblesType = (reward_bubble_type: number) => {
        onChange({reward_type: Level.REWARD_BUBBLES, reward_bubble_type});
    }

    const onChangeYoutubeUrl = (reward_youtube_url: string) => {
        onChange({reward_youtube_url});
    }

    const onChangeYoutubeDuration = (value: string) => {
        let reward_youtube_duration_secs = Number(value);
        if (!isNaN(reward_youtube_duration_secs)) {
            onChange({reward_youtube_duration_secs})
        }
    }

    const renderVideo = () => {
        if (level.local_video_path) {
            return <video playsInline controls src={normalizeSrc(level.local_video_path)}/>;
        } else {
            return (
                <YoutubeChecker
                    onStateChange={result => setYoutubeCheckState(result)}
                    youtubeUrl={level.reward_youtube_url!}/>
            );
        }
    }

    const renderDeleteVideoButton = () => {
        const isYoutubeVideoShown = [
            YOUTUBE_CHECK_STATE_SUCCESS,
            YOUTUBE_CHECK_STATE_FAIL,
        ].includes(youtubeCheckState);

        const isLocalVideoShown = level.local_video_path.length > 0;

        if (!isYoutubeVideoShown && !isLocalVideoShown) {
            return null;
        }

        return (
            <a className="btn-youtube-delete" onClick={() => {
                setYoutubeCheckState(YOUTUBE_CHECK_STATE_IN_PROGRESS);
                onChange({
                    local_video_path: '',
                    reward_youtube_url: '',
                });
            }}>
                <img src="/assets/images/delete.svg" alt=""/>
            </a>
        );
    }

    const renderVideoWrapper = () => {

        if (level.reward_youtube_url || level.local_video_path) {
            return (
                <div className="video-wrapper"
                     style={{/*display: youtubeCheckState == YOUTUBE_CHECK_STATE_SUCCESS ? 'block' : 'none'*/}}>
                    {/*<div className="video-cover"/>*/}
                    {renderVideo()}
                    {renderDeleteVideoButton()}
                </div>
            );
        }

        return (
            <div className="line-wrapper">
                <p>или загрузите с телефона</p>

                <a className="btn-back" onClick={() => onLoadVideo()}>
                    <span><img src="/assets/images/subtract.svg" alt=""/></span>
                </a>
            </div>
        );
    }

    const renderYoutube = () => {
        if (!isYoutube) {
            return null;
        }

        const youtubeResult = () => {
            switch (youtubeCheckState) {
                case YOUTUBE_CHECK_STATE_IN_PROGRESS:
                    return null; //<p>Получаем сведения о доступности видео</p>;
                case YOUTUBE_CHECK_STATE_WRONG_URL:
                    if (level.reward_youtube_url.length && !level.local_video_path) {
                        return <p>Некорректная ссылка</p>;
                    }
                    return null;
                case YOUTUBE_CHECK_STATE_SUCCESS:
                    return null; //<p>Видео доступно</p>;
                case YOUTUBE_CHECK_STATE_FAIL:
                    return <p>Видео недоступно</p>;
                default:
                    return null;
            }
        }

        return (
            <div>
                <label>
                    {level.local_video_path ? null : <input type="text"
                           onFocus={e => props.onScrollTo(e.target)}
                           placeholder="Ссылка на видео в YouTube или Vimeo"
                           value={level.reward_youtube_url || ''}
                           onChange={e => onChangeYoutubeUrl(e.target.value)}
                           name="links"/>}
                </label>
                <span className="youtube-check-result">
                    {youtubeResult()}
                </span>

                {renderVideoWrapper()}
            </div>
        );
    }

    const renderBubbles = () => {
        if (level.reward_type != Level.REWARD_BUBBLES) {
            return null;
        }
        let animation = (() => {
            switch (level.reward_bubble_type) {
                case 2:
                    return <AnimationTwo width="100%" height="200px"/>;
                case 3:
                    return <AnimationThree width="100%" height="200px"/>;
                case 4:
                    return <AnimationFour width="100%" height="200px"/>;
                case 5:
                    return <AnimationFive width="100%" height="200px"/>;
                case 1:
                default:
                    return <AnimationOne width="100%" height="200px"/>;
            }
        })();
        return (
            <div>
                <div className="video-wrapper" style={{backgroundImage: 'url("/assets/images/bg.png")'}}>
                    {animation}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="form form-password form-settings">
                <div className="popup-box">
                    <div className="popup-box-title">поощрение</div>
                    <p className="description">Поощрение происходит при переходе на этот уровень</p>
                    {[1, 2, 3, 4, 5].map(i => (
                        <label className="radio" key={i}>
                            <input className="radio-inp"
                                   type="radio"
                                   name="radio"
                                   onChange={_ => {
                                       setBubblesType(i);
                                   }}
                                   checked={isBubbles && (level.reward_bubble_type == i)}/>
                            <span className="radio-custom"/>
                            <span className="label">Мини-игра №{i}</span>
                        </label>
                    ))}
                    <label className="radio">
                        <input className="radio-inp"
                               type="radio"
                               name="radio"
                               onChange={_ => setReward(Level.REWARD_YOUTUBE)}
                               checked={isYoutube}/>
                        <span className="radio-custom"/>
                        <span className="label">Мультфильм</span>
                    </label>
                    {renderYoutube()}
                    {renderBubbles()}
                    <p className="description">Время переменки (сек)</p>
                    <label>
                        <input type="number"
                               onFocus={e => props.onScrollTo(e.target)}
                               onChange={e => onChangeYoutubeDuration(e.target.value)}
                               value={level.reward_youtube_duration_secs || ''}
                               name="time"/>
                    </label>
                </div>
            </div>
            <div className="button-group">
                <a className="btn btn-border" onClick={() => onDropProgress()}>
                    <span>Сбросить прогресс уровня</span>
                </a>
            </div>
        </>
    );
}
