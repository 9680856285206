import * as React from 'react';
import {Level} from "../models/level";
import {Task} from "../models/task";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {addTasks, setTaskImgLocalPath} from "../state/tasks/actions";
import {addLevels} from "../state/levels/actions";
import {BasePageProps} from "../types";
import {appStorage} from "../services/AppStorage";


interface InitialScreenProps extends BasePageProps<any> {
}

class InitialScreen extends React.Component<InitialScreenProps, any> {

    componentDidMount() {
        appStorage.getSettings().then(settings => {
            if (settings.onboarded) {
                this.props.history.replace('/levels');
            } else {
                this.props.history.replace('/onboarding');
            }
        });
    }

    render() {
        return <img src={"/assets/images/logo.svg"} alt=""/>;
    }
}

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
    }, dispatch)
);

export default connect(null, mapDispatchToProps)(InitialScreen);
