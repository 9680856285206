import React from 'react';


export default class AnimationThree extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    render() {
        return (
            <div style={{width: this.props.width, height: this.props.height}} ref={this.wrapperRef}>
                <canvas id="canvas"/>
            </div>
        );
    }

    componentDidMount() {
        setTimeout(() => {
            this.start();
        }, 200);
    }

    start() {
        const wrapperRef = this.wrapperRef;
        var canvas = document.querySelector('canvas');
        var c = canvas.getContext('2d');

        let mouseX, mouseY;

        canvas.height = this.wrapperRef.current.clientHeight;
        canvas.width = this.wrapperRef.current.clientWidth;


        class Particle {
            constructor(x, y, radius, color, velocity) {
                this.x = x;
                this.y = y;
                this.radius = radius;
                this.color = color;
                this.velocity = {
                    x: (Math.random() - 0.5) * velocity,
                    y: (Math.random() - 0.5) * velocity
                }
                var mouseRange = 120;


                this.draw = function () {
                    c.save();
                    c.beginPath();
                    c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
                    c.fillStyle = this.color;
                    c.shadowColor = this.color;
                    c.shadowBlur = 5;
                    c.fill();
                    c.closePath();
                    c.restore();
                }
                this.update = function () {
                    this.x += this.velocity.x;
                    this.y += this.velocity.y;
                    var defaultColor = color;
                    const xDist = mouseX - this.x;
                    const yDist = mouseY - this.y;


                    if (this.x + this.radius > wrapperRef.current.clientWidth || this.x - this.radius < 0) {
                        this.velocity.x = -this.velocity.x;
                    } else if (this.y + this.radius > wrapperRef.current.clientHeight || this.y - this.radius < 0) {
                        this.velocity.y = -this.velocity.y;
                    }

                    if (xDist < mouseRange && xDist > -mouseRange &&
                        yDist < mouseRange && yDist > -mouseRange) {
                        this.color = '#fff';
                        this.x += (4 * this.velocity.x);
                        this.y += (4 * this.velocity.y);
                    } else {
                        this.color = defaultColor;
                    }
                    this.draw();
                }
            }
        }

        var particlesArray = [];
        var colorsArray = ['#3498db', '#1abc9c', '#e74c3c', '#9b59b6'];

        const init = () => {
            particlesArray = [];
            for (let i = 0; i < 200; i++) {
                const randRadius = Math.random() * 8;
                const randX = Math.random() * this.wrapperRef.current.clientWidth - (2 * randRadius) + randRadius;
                const randY = Math.random() * this.wrapperRef.current.clientHeight - (2 * randRadius) + randRadius;
                const randVelocity = Math.random() * 6;
                var randColorIndex = Math.floor(Math.random() * colorsArray.length);
                particlesArray.push(new Particle(randX, randY, randRadius, colorsArray[randColorIndex], randVelocity));
            }
        }

        canvas.addEventListener('click', (e) => {
            for (let i = 0; i < 20; i++) {
                const randRadius = Math.random() * 15;
                const randVelocity = Math.random() * 10;
                var randColorIndex = Math.floor(Math.random() * colorsArray.length);
                particlesArray.push(new Particle(e.x, e.y, randRadius, colorsArray[randColorIndex], randVelocity))
            }
        });

        const animate = () => {
            if (!this.wrapperRef.current) {
                return;
            }
            c.clearRect(0, 0, this.wrapperRef.current.clientWidth, this.wrapperRef.current.clientHeight);
            particlesArray.forEach(particle => {
                particle.update();
            });
            if (!this.done) {
                requestAnimationFrame(animate);
            }
        }

        const resizeHandler = () => {
            canvas.height = this.wrapperRef.current.clientHeight;
            canvas.width = this.wrapperRef.current.clientWidth;
            init();
        };

        const pointerMoveHandler = (e) => {
            mouseX = e.touches ? (e.touches[0].layerX || e.layerX) : e.layerX;
            mouseY = e.touches ? (e.touches[0].layerY || e.layerY) : e.layerY;
            e.stopPropagation();
            e.preventDefault();
            return mouseX;
        }

        canvas.addEventListener('mousemove', pointerMoveHandler);
        canvas.addEventListener('touchmove', pointerMoveHandler);

        animate();
        init();
    }

    done = false;
    componentWillUnmount() {
        this.done = true;
    }
}
