import * as React from 'react';
import {IonContent} from "@ionic/react";
import {BasePageProps} from "../../types";

interface AboutProps extends BasePageProps<{task_id?: string}> {
}

export default function AboutScreen({history, match}: AboutProps) {

    const goBack = () => {
        let task_id = match.params.task_id;
        if (task_id) {
            history.replace(`/parent/task/${task_id}`)
        } else {
            history.replace('/parent/levels');
        }
    };

    const goToAboutLevels = () => {
        let task_id = match.params.task_id;
        if (task_id) {
            history.replace(`/parent/task_help/${task_id}`);
        } else {
            history.replace('/parent/task_help');
        }
    };

    return (
        <IonContent className="parent">
            <div className="wrapper">
                <div className="header">
                    <div className="container">
                        <a className="btn-back" onClick={() => goBack()}>
                            <span><img src="/assets/images/arrow-left.svg" alt=""/></span>
                        </a>
                        <div className="header-title">О приложении</div>
                    </div>
                </div>

                <div className="content">
                    <div className="container">
                        <div className="about-box">
                            <div className="about-box__img" />

                            <div className="about-box__title">Учимся находить недостающую букву по образцу (для всех
                                букв
                                каждого
                                слова)
                            </div>

                            <div className="box-text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque excepturi molestiae
                                    mollitia
                                    quod? Commodi cupiditate delectus inventore nobis pariatur possimus ut. Ab culpa
                                    exercitationem
                                    fuga molestiae, numquam pariatur repellendus similique.</p>
                            </div>
                        </div>

                        <div className="button-group">
                            <a className="btn btn-border" onClick={() => goToAboutLevels()}>
                                <span>Об уровнях</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </IonContent>
);
}

