import * as React from 'react';
import SequenceAnimation from "../SequenceAnimation";


const confettiImages: any[] = [];
for (let i = 0; i <= 83; i++) {
    const number = i.toString().padStart(5, '0');
    confettiImages.push(`/assets/sequences/confetti/Comp 6_${number}.png`);
}
const sadFaceImages: any[] = [];
for (let i = 0; i <= 15; i++) {
    const number = i.toString().padStart(5, '0');
    sadFaceImages.push(`/assets/sequences/sadFace/Comp 2_${number}.png`);
}
const presentOpensImages: any = [];
for (let i = 7; i >= 0; i--) {
    const number = i.toString().padStart(5, '0');
    presentOpensImages.push(`/assets/sequences/presentOpens/Comp 6_${number}.png`);
}
const thumbsUpImages: any = [];
for (let i = 0; i <= 23; i++) {
    const number = i.toString().padStart(5, '0');
    thumbsUpImages.push(`/assets/sequences/thumbsUp/Comp 1_${number}.png`);
}
const crossImages: any = [];
for (let i = 0; i <= 33; i++) {
    const number = i.toString().padStart(5, '0');
    crossImages.push(`/assets/sequences/crossNoText/Comp 1_${number}.png`);
}

interface SequenceProps {
    onDone: Function,
}

export function SequencesPreload() {
    const renderImages = () => {
        return [
            ...confettiImages,
            ...sadFaceImages,
            ...presentOpensImages,
            ...thumbsUpImages,
        ].map((image, i) => {
            return <img src={image} key={i} alt=""/>
        });
    };
    return (
        <div className="sequences-preload">
            {renderImages()}
        </div>
    );
}

export function ConfettiSequence({onDone}: SequenceProps) {
    return (<SequenceAnimation images={confettiImages} autoplay={true} loop={true} fps={30} onDone={onDone}/>);
}

export function SadFaceSequence({onDone}: SequenceProps) {
    return (<SequenceAnimation images={sadFaceImages} autoplay={true} loop={false} fps={20} onDone={onDone}/>);
}

export function PresentOpensSequence({onDone}: SequenceProps) {
    return (<SequenceAnimation images={presentOpensImages} autoplay={true} loop={false} fps={10} onDone={onDone}/>);
}

export function ThumbsUpSequence() {
    return (<SequenceAnimation images={thumbsUpImages} autoplay={true} loop={false} fps={20} onDone={() => {
    }}/>);
}

export function CrossNoTextSequence() {
    return (
        <SequenceAnimation images={crossImages} autoplay={true} loop={true} fps={30} onDone={() => {
        }}/>
    );
}
