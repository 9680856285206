export const levelOpenedBaseDir = '/assets/sounds/LevelOpened';
export const levelOpenedFiles = [
    "2-14.mp3",
    "2-15.mp3",
];

export const letsWorkBaseDir = '/assets/sounds/LetsWork';
export const letsWorkFiles = [
    "2-10.mp3",
    "2-2.mp3",
    "2-3.mp3",
    "2-7.mp3",
    "2-8.mp3",
];

export const taskCompletedBaseDir = '/assets/sounds/TaskCompleted';
export const taskCompletedFiles = [
    "1-11.mp3",
    "1-13.mp3",
    "1-15.mp3",
    "1-16.mp3",
    "1-18.mp3",
    "1-20.mp3",
    "1-21.mp3",
    "1-23.mp3",
    "1-25.mp3",
    "1-27.mp3",
    "1-29.mp3",
    "1-3.mp3",
    "1-32.mp3",
    "1-37.mp3",
    "1-4.mp3",
    "1-40.mp3",
    "1-42.mp3",
    "1-44.mp3",
    "1-45.mp3",
    "1-46.mp3",
    "1-5.mp3",
    "1-51.mp3",
    "1-6.mp3",
    "1-62.mp3",
    "1-63.mp3",
    "1-64.mp3",
    "1-65.mp3",
    "1-70.mp3",
    "1-71.mp3",
    "1-73.mp3",
    "1-74.mp3",
    "1-75.mp3",
    "1-76.mp3",
    "1-78.mp3",
    "1-80.mp3",
    "1-81.mp3",
    "1-84.mp3",
    "1-88.mp3",
    "1-9.mp3",
    "1-90.mp3",
];
