import React, {CSSProperties} from 'react';
import {PluginListenerHandle, Plugins} from "@capacitor/core";
import {Settings} from "../../models/settings";
import {isPlatform} from "@ionic/react";


const {Keyboard} = Plugins;

interface CrossGestureHandlerProps {
    onCross?: Function;
    onGoToParentMode: Function;
    onSetParentPassword: (password: string) => void;
    settings: Settings;
}

interface CrossGestureHandlerState {
    lastTouches: TouchEvent[],
    timeout: NodeJS.Timeout | undefined;
    loginModalVisible: boolean;
    keyboardHeight: number;
    passwordInput: string;
    passwordRepeatInput: string;
    errorString: string;
}

type Pos = {
    x: 'right' | 'left' | 'center',
    y: 'top' | 'bottom' | 'center',
};

type EdgesArgument = {
    leftEdge: number,
    topEdge: number,
    rightEdge: number,
    bottomEdge: number,
};

export default class CrossGestureHandler extends React.Component<CrossGestureHandlerProps, CrossGestureHandlerState> {

    private static readonly GESTURE_TIMEOUT = 2000;
    private static readonly EDGE_THRESHOLD_PERCENTS = 50;
    private static readonly GESTURE_MIN_SIZE_PERCENTS = 20;

    private unsubscribeWindowListeners: () => void = () => {
    };
    private passwordRepeatInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: CrossGestureHandlerProps) {
        super(props);
        this.state = {
            lastTouches: [],
            timeout: undefined,
            loginModalVisible: false,
            keyboardHeight: 0,
            passwordInput: '',
            passwordRepeatInput: '',
            errorString: '',
        };
        this.passwordRepeatInputRef = React.createRef<HTMLInputElement>();
    }

    private keyboardListeners: PluginListenerHandle[] = [];

    private updateState(state: Partial<CrossGestureHandlerState>) {
        this.setState(prev => ({
            ...prev,
            ...state,
        }));
    }

    private resetTimeout() {
        clearTimeout(this.state.timeout!);
        this.setState(prev => ({
            ...prev,
            timeout: setTimeout(() => {
                this.updateState({
                    timeout: undefined,
                    lastTouches: [],
                });
            }, CrossGestureHandler.GESTURE_TIMEOUT)
        }));
    }

    private clearTouches() {
        clearTimeout(this.state.timeout!);
        this.updateState({
            lastTouches: [],
            timeout: undefined,
        });
    }

    private touchStartHandler(event: TouchEvent) {
        this.pushTouch(event);
        this.resetTimeout();
    }

    private touchEndHandler(event: TouchEvent) {
        this.pushTouch(event);
        this.resetTimeout();
        if (this.state.lastTouches.length >= 4) {
            this.calcCross();
        }
    }

    componentWillUnmount() {
        for (let listener of this.keyboardListeners) {
            if (typeof listener.remove === 'function') {
                listener.remove();
            }
        }
        this.unsubscribeWindowListeners();
    }

    componentDidMount() {
        this.keyboardListeners.push(Keyboard.addListener("keyboardWillShow", (e) => {
            this.setState(prev => ({...prev, keyboardHeight: e.keyboardHeight}));
        }));
        this.keyboardListeners.push(Keyboard.addListener("keyboardDidHide", () => {
            this.setState(prev => ({...prev, keyboardHeight: 0}));
        }));

        let touchStartHandler = (e: TouchEvent) => this.touchStartHandler(e);
        let touchEndHandler = (e: TouchEvent) => this.touchEndHandler(e);
        window.addEventListener('touchstart', touchStartHandler);
        window.addEventListener('touchend', touchEndHandler);
        this.unsubscribeWindowListeners = () => {
            window.removeEventListener('touchstart', touchStartHandler);
            window.removeEventListener('touchend', touchEndHandler);
        }
    }

    private static getOppositePos(pos: Pos): Pos {
        const xOpposites = {
            'left': 'right',
            'right': 'left',
            'center': 'center',
        };
        const yOpposites = {
            'top': 'bottom',
            'bottom': 'top',
            'center': 'center',
        };

        return {
            x: xOpposites[pos.x],
            y: yOpposites[pos.y],
        } as Pos;
    }

    private getPointsFromEvents(): number[] {
        if (this.state.lastTouches.length < 4) {
            return [];
        }
        const lastTouches = this.state.lastTouches.slice(this.state.lastTouches.length - 4);
        let result = [];
        for (let touchEvent of lastTouches) {
            result.push(touchEvent.changedTouches[0].clientX);
            result.push(touchEvent.changedTouches[0].clientY);
        }

        return result;
    }

    private calcCross() {
        if (this.state.lastTouches.length < 4) {
            return;
        }

        const coordinates = this.getPointsFromEvents();
        if (coordinates.length != 8) {
            console.warn('Not enough or too many coordinates', coordinates.length);
            return;
        }
        const [x1, y1, x2, y2, x3, y3, x4, y4] = coordinates;
        const [leftEdge, topEdge, rightEdge, bottomEdge] = [
            Math.min(x1, x2, x3, x4),
            Math.min(y1, y2, y3, y4),
            Math.max(x1, x2, x3, x4),
            Math.max(y1, y2, y3, y4),
        ];
        const [width, height] = [rightEdge - leftEdge, bottomEdge - topEdge];
        const minSize = Math.min(
            window.innerWidth * (CrossGestureHandler.GESTURE_MIN_SIZE_PERCENTS / 100),
            window.innerHeight * (CrossGestureHandler.GESTURE_MIN_SIZE_PERCENTS / 100),
        );

        if (width < minSize || height < minSize) {
            console.warn('Gesture zone too small', {width, height, minSize});
            return;
        }

        const edges: EdgesArgument = {leftEdge, topEdge, rightEdge, bottomEdge};
        const [firstTouchStart, firstTouchEnd, lastTouchStart, lastTouchEnd] = [
            CrossGestureHandler.calcPos(x1, y1, edges),
            CrossGestureHandler.calcPos(x2, y2, edges),
            CrossGestureHandler.calcPos(x3, y3, edges),
            CrossGestureHandler.calcPos(x4, y4, edges),
        ];


        const posEquals = (posA: Pos, posB: Pos): boolean => {
            return posA.x == posB.x && posA.y == posB.y;
        };

        const isCentral = (pos: Pos): boolean => {
            return pos.x == 'center' && pos.y == 'center';
        };

        console.log({
            firstTouchStart,
            firstTouchEnd,
            lastTouchStart,
            lastTouchEnd,
        });
        const validGesture =
            posEquals(firstTouchStart, CrossGestureHandler.getOppositePos(firstTouchEnd)) &&
            posEquals(lastTouchStart, CrossGestureHandler.getOppositePos(lastTouchEnd)) &&
            !isCentral(firstTouchStart) && !isCentral(firstTouchEnd) && !isCentral(lastTouchStart) && !isCentral(lastTouchEnd) &&
            (firstTouchStart.x == lastTouchStart.y || firstTouchStart.y == lastTouchStart.x) &&
            (firstTouchStart.x == 'center' || firstTouchStart.y == 'center')

        if (validGesture) {
            this.clearTouches();
            if (this.props.settings.askPassword === false) {
                this.props.onGoToParentMode();
            } else {
                this.updateState({loginModalVisible: true});
            }
        }
    };

    private static calcPos(x: number, y: number, {leftEdge, rightEdge, topEdge, bottomEdge}: EdgesArgument): Pos {

        let xPos, yPos;
        if (x <= leftEdge) {
            xPos = 'left';
        } else if (x >= rightEdge) {
            xPos = 'right';
        } else {
            xPos = 'center';
        }
        if (y <= topEdge) {
            yPos = 'top';
        } else if (y >= bottomEdge) {
            yPos = 'bottom';
        } else {
            yPos = 'center';
        }
        return {x: xPos, y: yPos} as Pos;
    };

    render() {
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!this.state.loginModalVisible) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        let modalStyle: CSSProperties = {};

        if (this.state.keyboardHeight && !isPlatform('android')) {
            modalStyle.top = `calc(50% - ${this.state.keyboardHeight / 4}px)`;
        }


        const renderModalContent = () => {
            if (this.props.settings.parentPassword) {
                return (
                    <div className={modalClass} style={modalStyle}>
                        <div className="modal-title">Вход в родительский режим</div>
                        <div className="form form-modal">
                            <label>
                                <input type="text" value={this.state.passwordInput}
                                       onKeyPress={evt => {
                                           if (evt.key == 'Enter') {
                                               this.tryToLogin();
                                           }
                                       }}
                                       onChange={evt => this.setPasswordInput((evt.target as any).value)}
                                       placeholder="пароль"/>
                            </label>
                            {this.state.errorString ? <div className="error">{this.state.errorString}</div> : null}
                            <button type="submit" className="btn btn-accent" onClick={() => this.tryToLogin()}>
                                <span>войти</span>
                            </button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={modalClass} style={modalStyle}>
                        <div className="modal-title">Задайте родительский пароль</div>
                        <div className="form form-modal form-modal2">
                            <label>
                                <input type="text"
                                       placeholder="Пароль"
                                       onKeyPress={evt => {
                                           if (evt.key == 'Enter') {
                                               this.passwordRepeatInputRef.current?.focus();
                                           }
                                       }}
                                       onChange={evt => this.setPasswordInput((evt.target as any).value)}
                                       value={this.state.passwordInput}/>
                            </label>
                            <label>
                                <input type="text"
                                       placeholder="Повтор пароля"
                                       ref={this.passwordRepeatInputRef}
                                       onKeyPress={evt => {
                                           if (evt.key == 'Enter') {
                                               this.setNewPassword();
                                           }
                                       }}
                                       onChange={evt => this.setPasswordRepeatInput((evt.target as any).value)}
                                       value={this.state.passwordRepeatInput}/>
                            </label>
                            {this.state.errorString ? <div className="error">{this.state.errorString}</div> : null}
                            <button type="submit" className="btn btn-accent" onClick={() => this.setNewPassword()}>
                                <span>Задать</span>
                            </button>
                        </div>
                    </div>
                );
            }
        };

        return (

            <div className={this.state.loginModalVisible ? '' : 'hidden'}>
                <div className={overlayClass} onClick={() => this.dismissModal()}/>
                {renderModalContent()}
            </div>
        );
    }

    private dismissModal() {
        this.updateState({loginModalVisible: false});
    }

    private setPasswordInput(passwordInput: string) {
        this.updateState({passwordInput});
    }

    private setPasswordRepeatInput(passwordRepeatInput: string) {
        this.updateState({passwordRepeatInput})
    }

    private setNewPassword() {
        if (this.state.passwordInput != this.state.passwordRepeatInput) {
            this.updateState({errorString: 'Пароли не совпадают'});
        } else {
            this.props.onSetParentPassword(this.state.passwordInput);
            this.props.onGoToParentMode();
        }
    }

    private tryToLogin() {
        if (this.state.passwordInput == this.props.settings.parentPassword) {
            this.props.onGoToParentMode();
        } else {
            this.updateState({errorString: 'Неверный пароль'});
        }
    }

    private pushTouch(event: TouchEvent) {
        this.setState(prev => ({...prev,
            lastTouches: [...this.state.lastTouches, event],
        }));
    }
}
