import * as React from 'react';
import {Task} from "../../models/task";

export function TaskItemComponent({task, index, onClick}: {task: Task, index: number, onClick: (task: Task) => void}) {

    const classMap = {
        1: 'card-z-5 card-r-5',
        2: 'mt-37',
        0: 'card-r5 mt-80',
    };
    let className = "card z-index-2 ";

    // @ts-ignore
    className += classMap[(index + 1) % 3];


    const cardName = () => {
        const word = task.done ? task.word : task.wordWithHiddenChars();
        return word.split('').map((char, i) => (
            <div key={i} className="card-name__item">{char}</div>)
        );
    }

    const star = () => {
        if (task.done) {
            return (
                <div className="icon-stars">
                    <div className="star-yellow-wrapper">
                        <img src={"/assets/images/star-yellow.svg"} alt="" />
                    </div>
                </div>
            );
        }
        return null;
    }

    const cardType = () => {
        switch (task.type) {
            case "same_char": return 1;
            case "char": return 2;
            case "word": return 3;
            default: return 1;
        }
    }

    const cardDifficulty = () => {
        return task.difficulty;
    };

    return (
        <div className="col-3">
            <div className={className} onClick={() => onClick(task)}>
                <div className="card-img">
                    {star()}
                    <img src={task.imageSrc} alt="" />
                </div>
                <div className="card-characteristics">
                    <div className="card-characteristics__item">
                        <div className="card-characteristics__icon">
                            <img src={"/assets/images/mode-icon.svg"} alt="" />
                        </div>
                        <div className="card-characteristics__text">{cardType()}</div>
                    </div>
                    <div className="card-characteristics__item">
                        <div className="card-characteristics__icon">
                            <img src={"/assets/images/difficult.svg"} alt="" />
                        </div>
                        <div className="card-characteristics__text">{cardDifficulty()}</div>
                    </div>
                </div>
                <div className="card-name">
                    {cardName()}
                </div>
            </div>
        </div>
    );
}
