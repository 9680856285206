import * as React from 'react';
import {IonContent, IonPage, useIonViewWillEnter, useIonViewWillLeave} from "@ionic/react";
import {BasePageProps} from "../../../types";
import {NativePageTransitions, NativeTransitionOptions} from "@ionic-native/native-page-transitions";
import {slideBackTransition, slideForwardTransition} from "../../../Transitions";

interface TaskHelpScreenProps extends BasePageProps<{ task_id?: string }> {
}

export default function TaskHelpScreen({history, match}: TaskHelpScreenProps) {


    const goBack = () => {
        let task_id = match.params.task_id;
        if (task_id) {
            slideBackTransition();
            history.replace(`/parent/task/${task_id}`);
        } else {
            history.replace('/parent/levels');
        }
    }

    const goToAbout = () => {
        slideForwardTransition();
        let task_id = match.params.task_id;
        if (task_id) {
            history.replace(`/parent/about/${task_id}`);
        } else {
            history.replace('/parent/about');
        }
    }

    return (
        <IonPage>
            <IonContent className="parent">
                <div className="wrapper">
                    <div className="header">
                        <div className="container">
                            <a className="btn-back" onClick={() => goBack()}>
                                <span><img src="/assets/images/arrow-left.svg" alt=""/></span>
                            </a>
                            <div className="header-title">информация</div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container">
                            <div className="about-box">
                                <div className="about-box__line">
                                    <div className="about-box__number">1</div>
                                    <p>тип карточки</p>
                                    <div className="icon-card"><img src="/assets/images/card.svg" alt=""/></div>
                                </div>
                                <h3>Найди такую же букву</h3>

                                <div className="about-box__title">Учимся находить недостающую букву по образцу (для всех
                                    букв каждого слова)
                                </div>

                                <div className="box-text">
                                    <p>В верхней надписи есть две строки: слово целиком и слово без одной случайной
                                        буквы.
                                        Нужно выбрать
                                        нужную букву. Положение нужной буквы меняется каждый раз случайным образом.</p>
                                </div>

                                <ul className="list-about-box">
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">1</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней строке <span
                                                className="color-green">первая буква</span> выделена <span
                                                className="color-red">цветом</span>, во второй строке не хватает первой
                                                буквы.</p>
                                            <p>В «кассе букв» есть <span className="color-green">столько же букв</span>,
                                                из
                                                них 1 нужная
                                                выделена <span className="color-red">цветом</span>.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">2</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней строке <span
                                                className="color-green">любая буква</span> выделена <span
                                                className="color-red">цветом</span>, во второй строке не хватает <span
                                                className="color-red">первой буквы</span>. В «кассе букв» есть столько
                                                же
                                                букв, из них
                                                <span className="color-green">1 нужная</span>
                                                <span className="color-red">выделена цветом</span>.</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">3</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней строке не хватает <span
                                                className="color-green">одной буквы</span>.
                                                В «кассе букв»
                                                есть столько же букв, из них <span
                                                    className="color-green">1 нужная</span>.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="about-box">
                                <div className="about-box__line">
                                    <div className="about-box__number">2</div>
                                    <p>тип карточки</p>
                                    <div className="icon-card"><img src="/assets/images/card.svg" alt=""/></div>
                                </div>
                                <h3>Найди нужную букву</h3>

                                <div className="about-box__title">Учимся вписывать недостающую букву в слове (для всех
                                    букв
                                    каждого слова)
                                </div>

                                <div className="box-text">
                                    <p>В верхней надписи одна строка, в ней нет одной буквы. Нужно выбрать нужную букву
                                        из
                                        «кассы».
                                    </p>
                                    <p>Положение нужной буквы меняется каждый раз случайным образом.</p>
                                </div>

                                <ul className="list-about-box">
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">1</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней надписи не хватает <span
                                                className="color-green">одной буквы</span>
                                                в слове. В «кассе букв» есть все буквы слова, <span
                                                    className="color-green">нужная буква</span> выделена <span
                                                    className="color-red">цветом</span>.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">2</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней надписи не хватает <span
                                                className="color-green">одной буквы</span> в слове. В
                                                «кассе букв» есть <span className="color-green">все буквы слова</span>.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">3</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней надписи не хватает <span
                                                className="color-green">одной буквы</span>
                                                в слове. В «кассе букв» есть все буквы слова и <span
                                                    className="color-green">1 «лишняя» буква</span>.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">4</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В верхней надписи не хватает <span
                                                className="color-green">одной буквы</span>
                                                в слове. В «кассе букв» есть все буквы слова и <span
                                                    className="color-green">3 «лишние» буквы</span>.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="about-box">
                                <div className="about-box__line">
                                    <div className="about-box__number">3</div>
                                    <p>тип карточки</p>
                                    <div className="icon-card"><img src="/assets/images/card.svg" alt=""/></div>
                                </div>
                                <h3>Напиши слово</h3>

                                <div className="about-box__title">Учимся вписывать слово целиком по буквам</div>

                                <div className="box-text">
                                    <p>В верхней надписи нет букв (есть только клеточки для них). Нужно выбрать нужные
                                        буквы
                                        из «кассы».</p>
                                    <p>Положение букв в "кассе букв" меняется каждый раз случайным образом.</p>
                                </div>

                                <ul className="list-about-box">
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">1</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В «кассе букв» только <span className="color-green">нужные буквы</span>.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">2</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В «кассе букв» на <span className="color-green">1 букву  больше</span>,
                                                нужные буквы выделены <span className="color-red">цветом</span>.</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">3</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В «кассе букв» на <span className="color-green">3 буквы больше</span>,
                                                нужные
                                                буквы выделены <span className="color-red">цветом</span>.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">4</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В «кассе букв» на <span className="color-green">1 букву больше</span>,
                                                все
                                                буквы одного цвета.</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="list-about-box__line">
                                            <div className="list-about-box__num">5</div>
                                            <p>сложность</p>
                                            <div className="icon-card"><img src="/assets/images/diffcuit.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="list-about-box__text">
                                            <p>В «кассе букв» на <span className="color-green">3 буквы больше</span>,
                                                все
                                                буквы одного цвета.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="button-group">
                                <a className="btn btn-border" onClick={() => goToAbout()}>
                                    <span>О приложении</span>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
