import * as React from 'react';
import {Task} from "../../models/task";
import {Level} from "../../models/level";
import {LevelItemComponent} from "./LevelItemComponent";


interface LevelListComponentProps {
    tasks: Task[],
    levels: Level[],
    onClick: (level: Level) => void,
    onCloseParentMode?: Function,
    onShowAbout?: Function,
    onVolume?: Function,
    onSettings?: Function,
    onCreateLevel?: (level: Level) => void,
    parentMode: boolean,
    soundsEnabled?: boolean,
}

export function LevelListComponent({tasks, levels, parentMode, onCreateLevel, onClick, onVolume, onSettings, onCloseParentMode, onShowAbout, soundsEnabled}: LevelListComponentProps) {


    const clickHandler = (level: Level) => () => {
        const completedTasksCount = tasks.filter(t => t.done).length;
        const requiredTasksCount = level.completed_tasks_required;
        if (completedTasksCount >= requiredTasksCount || parentMode) {
            onClick(level);
        } else {
            console.warn("Level is not available");
        }
    }

    const createLevel = () => {
        if (onCreateLevel) {
            const newLevelNumber = Math.max(...levels.filter(l => !!l.level_number).map(l => l.level_number)) + 1;
            const newId = -(Math.max(...levels.filter(l => !!l.id).map(l => Math.abs(l.id as number))) + 1);
            const completedTasksRequired = tasks.filter(t => levels.filter(l => l.level_number < newLevelNumber).map(l => l.id).includes(t.level_id)).length;
            const newLevel = new Level({
                id: newId,
                level_number: newLevelNumber,
                completed_tasks_required: completedTasksRequired,
            });
            onCreateLevel(newLevel);
        }
    }

    const renderLevels = (tasks: Task[], levels: Level[]) => {
        let result = [...levels]
            .sort((a, b) => a.level_number > b.level_number ? 1 : -1)
            .map((level, i) => {
                const tasksDone = tasks.filter(t => t.done).length;
                const prevOpened = tasksDone >= levels[(i || 1) - 1].completed_tasks_required;
                const opened = tasksDone >= level.completed_tasks_required;
                const isNext = prevOpened && !opened;
                const levelTasks = tasks.filter(t => t.level_id == level.id);
                const completionPercents = levelTasks.length ? ((levelTasks.filter(t => t.done).length / levelTasks.length) * 100) : 0;
                return (<LevelItemComponent
                    level={level}
                    completionPercents={completionPercents}
                    parentMode={parentMode}
                    isNext={isNext}
                    opened={opened}
                    key={level.id}
                    tasks={levelTasks}
                    onClick={clickHandler(level)}/>);
            });
        if (parentMode) {
            result.push(
                <div className="level-box" key={"add-level-btn"}>
                    <a className="btn-add btn-back" onClick={() => createLevel()}>
                        <span><img src="/assets/images/btn-add.svg" alt=""/></span>
                    </a>
                </div>
            );
        }

        return result;
    }

    const renderStarsCounter = () => {
        return <div className="letter">
            <div className="letter-icon">
                <img src={"/assets/images/star-white.svg"} alt=""/>
            </div>
            <div className="letter-num">{tasks.filter(t => t.done).length}</div>
            <div className="tooltip-wrapper">
                <div className="tooltip-box">Набрано звёзд</div>
            </div>
        </div>;
    };

    const showAbout = () => {
        if (onShowAbout) {
            onShowAbout();
        }
    }

    const renderHeader = () => {
        if (parentMode) {
            return <div className="header">
                <div className="container">
                    <a className="btn-back" onClick={() => onCloseParentMode!()}>
                        <span>
                            <img src="/assets/images/btn-close.svg" alt=""/>
                        </span>
                    </a>
                    <a className="btn-back" onClick={() => onVolume ? onVolume() : null}>
                        <span>
                            <img src={`/assets/images/btn-volume${false === soundsEnabled ? '-off' : ''}.svg`} alt=""/>
                        </span>
                    </a>
                    <a className="btn-back" onClick={() => onSettings ? onSettings() : null}>
                        <span>
                            <img src="/assets/images/btn-settings.svg" alt=""/>
                        </span>
                    </a>
                    <a className="btn-back" onClick={showAbout}>
                        <span>
                            <img src="/assets/images/btn-info.svg" alt=""/>
                        </span>
                    </a>

                    {renderStarsCounter()}
                </div>

            </div>
        } else {
            return <div className="header">
                <div className="container">
                    {renderStarsCounter()}
                </div>
            </div>;
        }
    };

    return (
        <div className="wrapper">
            {renderHeader()}


            <div className="content">
                <div className="container">
                    <div className="level-wrapper">
                        {renderLevels(tasks, levels)}
                    </div>
                </div>
            </div>
        </div>
    );
}
