import {Action} from "redux";
import {Task} from "../../models/task";

export const ADD_TASKS_ACTION = 'ADD_TASKS';
export const ADD_TASK_ACTION = 'ADD_TASK';
export const TASK_DONE_ACTION = 'TASK_DONE';
export const SET_TASK_LOCAL_IMG_PATH = 'SET_TASK_LOCAL_IMG_PATH';
export const UPDATE_TASK_ACTION = 'UPDATE_TASK';
export const SET_TASK_HIDDEN_CHAR_ACTION = 'SET_TASK_HIDDEN_CHAR';
export const REMOVE_TASK_ACTION = 'REMOVE_TASK';
export const DROP_ALL_PROGRESS_ACTION = 'DROP_ALL_PROGESS';

export const taskDone = (task_id: number) => {
    return {
        type: TASK_DONE_ACTION,
        task_id,
    } as Action;
};

export const addTasks = (tasks: Task[]) => ({
    type: ADD_TASKS_ACTION,
    tasks
});

export const setTaskImgLocalPath = (task_id: number, img_local_path: string) => ({
    type: SET_TASK_LOCAL_IMG_PATH,
    task_id,
    img_local_path,
});

export const updateTask = (task: Task) => ({
    type: UPDATE_TASK_ACTION,
    task,
});

export const addTask = (task: Task) => ({
    type: ADD_TASK_ACTION,
    task,
});


export const setTaskHiddenCharPos = (task_id: number, index: number) => {
    return ({
        type: SET_TASK_HIDDEN_CHAR_ACTION,
        task_id,
        index,
    });
}

export const removeTask = (task_id: number) => ({
    type: REMOVE_TASK_ACTION,
    task_id,
});

export const dropAllProgress = () => ({
    type: DROP_ALL_PROGRESS_ACTION,
});
