import * as React from 'react';
import {Level} from "../../models/level";
import {Task} from "../../models/task";
import {LevelListComponent} from "../../components/LevelList/LevelListComponent";
import {connect} from "react-redux";
import {match} from "react-router";
import {History} from 'history';
import {IonContent, isPlatform} from "@ionic/react";
import {appStorage} from "../../services/AppStorage";
import {bindActionCreators} from "redux";
import {addLevel} from "../../state/levels/actions";
import CrossGestureHandler from "../../components/CrossGestureHandler/CrossGestureHandler";
import {Settings} from "../../models/settings";
import {platform} from "os";


interface LevelsListScreenProps {
    history: History,
    match: match,
    levels: Level[];
    tasks: Task[];
}

interface LevelsListScreenState {
    modalVisible: boolean,
    currentPassword: string,
    settings: Settings,
}

class LevelsListScreen extends React.Component<LevelsListScreenProps, LevelsListScreenState> {

    constructor(props: LevelsListScreenProps) {
        super(props);
        this.state = {
            modalVisible: false,
            currentPassword: '',
            settings: {} as Settings,
        };
        appStorage.getSettings().then(settings => {
            this.setState(prev => ({...prev, settings}));
        })
    }
    setCurrentPassword(currentPassword: string) {
        this.setState(prev => ({...prev, currentPassword}))
    }

    levelClicked(level: Level) {
        if (level.id) {
            const notLoadedTasks = this.props.tasks
                .filter(t => t.level_id == level.id)
                .filter(t => t.word?.trim().length)
                .filter(t => t.img_path || t.img_external_path)
                .filter(t => !t.img_local_path);
            if (notLoadedTasks.length && !isPlatform('desktop')) {
                console.error('Not loaded tasks');
                return;
            }
            this.props.history.replace(`/level/${level.id}`);
        }
    }

    goToParentMode() {
        this.props.history.replace(`/parent/levels`);
    }

    componentDidMount() {
        appStorage.getParentPassword().then(password => password ? this.setCurrentPassword(password) : null);
    }
    componentWillUnmount() {
    }


    setNewPassword(password: string) {
        appStorage.setParentPassword(password);
    };

    renderLevels(levels: Level[], tasks: Task[]) {
        return (
            <LevelListComponent
                parentMode={false}
                onClick={(level) => this.levelClicked(level)}
                tasks={tasks}
                levels={levels}
            />
        );
    }

    render() {
        return (
            <IonContent>
                {this.renderLevels(this.props.levels, this.props.tasks)}
                <CrossGestureHandler onGoToParentMode={() => this.goToParentMode()}
                                     onSetParentPassword={(password) => this.setNewPassword(password)}
                                     settings={this.state.settings} />
            </IonContent>
        );
    }
}

const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
};
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addLevel,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(LevelsListScreen);
