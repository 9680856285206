import * as React from 'react';
import {RefObject} from 'react';
import {IonContent, IonPage} from "@ionic/react";
import {BasePageProps} from "../../../types";
import {Task} from "../../../models/task";
import {Level} from "../../../models/level";
import {bindActionCreators} from "redux";
import {removeTask, setTaskHiddenCharPos, updateTask} from "../../../state/tasks/actions";
import {connect} from "react-redux";
import ParentTaskEditComponent from "../../../components/ParentTaskEdit/ParentTaskEditComponent";
import {slideBackTransition, slideForwardTransition} from "../../../Transitions";

interface TaskEditScreenProps extends BasePageProps<{ task_id: string }> {
    tasks: Task[],
    levels: Level[],
    updateTask: (task: Task) => void,
    removeTask: (task_id: number) => void,
    setTaskHiddenCharPos: (task_id: number, i: number) => void,
}

function TaskEditScreen({history, match, tasks, updateTask, setTaskHiddenCharPos, removeTask}: TaskEditScreenProps) {

    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
    const ionContentRef = React.useRef<HTMLIonContentElement>() as RefObject<HTMLIonContentElement>;

    const goBack = () => {
        slideBackTransition();
        let task = getTask();
        if (task) {
            history.replace(`/parent/level/${task.level_id}`)
        } else {
            history.replace('/parent/levels');
        }
    }

    const selectPhoto = () => {
        let task = getTask();
        if (task) {
            slideForwardTransition();
            history.replace(`/parent/photo/${task.id}`);
        }
    }

    const goToInfo = () => {
        slideForwardTransition();
        history.replace(`/parent/task_help/${match.params.task_id}`);
    }

    const getTask = () => {
        return tasks.find(t => t.id == Number(match.params.task_id));
    }

    const onChange = (changes: Partial<Task>) => {
        console.log({changes});
        updateTask(new Task({...getTask(), ...changes}));
    }

    const renderDeleteModal = () => {
        let modalClass = "modal";
        let overlayClass = "overlay";
        if (!deleteModalVisible) {
            modalClass += " hidden";
            overlayClass += " hidden";
        }
        let task = getTask()!;
        let level_id = task.level_id;
        return (
            <div className={deleteModalVisible ? '' : 'hidden'}
                 onClick={() => setDeleteModalVisible(false)}>
                <div className={overlayClass}/>
                <div className={modalClass}>
                    <div className="modal-title">Удаление карточки</div>
                    <div className="box-text">
                        <p>Вы уверены, что хотите удалить карточку?</p>
                    </div>

                    <div className="button-group button-group-row">
                        <a className="btn btn-accent" onClick={() => {
                            removeTask(task.id!);
                            setDeleteModalVisible(false);
                            slideBackTransition();
                            history.replace(`/parent/level/${level_id}`);
                        }}>
                            <span>Да</span>
                        </a>
                        <a className="btn btn-accent" onClick={() => setDeleteModalVisible(false)}>
                            <span>Нет</span>
                        </a>
                    </div>

                </div>
            </div>
        );
    }

    if (!getTask()) {
        return null;
    }

    return (
        <IonPage>
            <IonContent className="parent" ref={ionContentRef}>
                <div className="wrapper">
                    <div className="header">
                        <div className="container">
                            <a className="btn-back" onClick={() => goBack()}>
                            <span>
                                <img src="/assets/images/arrow-left.svg" alt=""/>
                            </span>
                            </a>
                            <a className="btn btn-back" onClick={() => goToInfo()}>
                            <span>
                                <img src="/assets/images/btn-info.svg" alt=""/>
                            </span>
                            </a>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container">
                            <ParentTaskEditComponent
                                task={getTask()!}
                                onDelete={() => setDeleteModalVisible(true)}
                                onSelectPhoto={() => selectPhoto()}
                                onSetTaskHiddenCharPos={(task, i) => {
                                    setTaskHiddenCharPos(task.id!, i);
                                }}
                                onScrollTo={(elementRef: any) => {
                                    ionContentRef.current?.scrollToPoint(0, elementRef.offsetTop - ((document.body.clientHeight) / 6), 500);
                                }}
                                onChange={changes => onChange(changes)}/>
                        </div>
                    </div>
                </div>

                {renderDeleteModal()}

            </IonContent>
        </IonPage>
    );
}

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        updateTask,
        removeTask,
        setTaskHiddenCharPos,
    }, dispatch)
);
const mapStateToProps = (state: any) => {
    const {tasks, levels} = state
    return {tasks: tasks.tasks, levels: levels.levels};
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskEditScreen);
