import {Task} from '../models/task';
import {Level} from "../models/level";
import {Settings} from "../models/settings";

export class AppStorage {

    private static readonly LEVELS_KEY = '@levels';
    private static readonly TASKS_KEY = '@tasks';
    private static readonly SETTINGS_KEY = '@settings';

    constructor() {
        try {
            const rawTasks = localStorage.getItem(AppStorage.TASKS_KEY);
            if (typeof rawTasks !== 'string') {
                throw new Error();
            }
            const tasks = JSON.parse(rawTasks);
            if (typeof tasks !== 'object' || !tasks.hasOwnProperty('length')) {
                throw new Error();
            }
            AppStorage.tasks = tasks.map((task: any) => Task.fromJson(task));
        } catch (e) {
        }

        try {
            const rawLevels = localStorage.getItem(AppStorage.LEVELS_KEY);
            if (typeof rawLevels !== 'string') {
                throw new Error();
            }
            const levels = JSON.parse(rawLevels);
            if (typeof levels !== 'object' || !levels .hasOwnProperty('length')) {
                throw new Error();
            }
            AppStorage.levels = levels.map((level: any) => Level.fromJson(level));
        } catch (e) {
        }
    }

    private static tasks: Task[] = [];

    private static levels: Level[] = [];

    async getTasks(): Promise<Task[]> {
        return AppStorage.tasks;
    }

    async getLevels(): Promise<Level[]> {
        return AppStorage.levels;
    }

    async setLevels(levels: Level[]) {
        AppStorage.levels = levels;
        this.flush();
    }

    async setTasks(tasks: Task[]) {
        AppStorage.tasks = tasks;
        this.flush();
    }

    async completeTask(task_id: number) {
        const task = AppStorage.tasks.find(t => t.id == task_id);
        if (task) {
            task.done = true;
            this.flush();
        }
    }

    private flush() {
        const tasks = JSON.stringify(AppStorage.tasks);
        const levels = JSON.stringify(AppStorage.levels);
        localStorage.setItem(AppStorage.LEVELS_KEY, levels);
        localStorage.setItem(AppStorage.TASKS_KEY, tasks);
    }

    async setTaskLocalImg(task_id: number, local_img_path: string) {
        const task = AppStorage.tasks.find(t => t.id == task_id);
        if (task) {
            task.img_local_path = undefined;
            this.flush();
        }
    }

    async getParentPassword() {
        const settings = await this.getSettings();
        return settings.parentPassword;
    }

    async setParentPassword(newPassword: string) {
        const settings = await this.getSettings();
        return await this.updateSettings({...settings, parentPassword: newPassword});
    }

    async getSettings(): Promise<Settings> {
        try {
            const settingsJson = localStorage.getItem(AppStorage.SETTINGS_KEY);
            if (!settingsJson) {
                throw new Error();
            }
            return JSON.parse(settingsJson);
        } catch (e) {
            return new Settings();
        }
    }

    async updateSettings(settings: Partial<Settings>) {
        const currentSettings = await this.getSettings();
        localStorage.setItem(AppStorage.SETTINGS_KEY, JSON.stringify({...currentSettings, ...settings}));
    }

    clearSettings() {
        localStorage.removeItem(AppStorage.SETTINGS_KEY);
    }
}

export const appStorage = new AppStorage();
