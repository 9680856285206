import * as React from "react";
import {Task} from "../../models/task";
import {CharacterToChoose} from "./types";

export interface GameInputComponentProps {
    task: Task;
    charClicked: (char: CharacterToChoose) => void;
    charactersToChoose: CharacterToChoose[];
}

export function GameInputComponent({task, charClicked, charactersToChoose}: GameInputComponentProps) {
    const taskType = task.type;

    const charactersToChooseRender = () => {
        return charactersToChoose.filter(c => !!c.char.trim()).map((character, i) => {
            let className = 'card-product-word__box';
            const highlightCorrectChar =
                (task.type == Task.TYPE_FIND_SAME_CHAR && task.difficulty < 3) ||
                (task.type == Task.TYPE_FIND_CHAR && task.difficulty == 1) ||
                (task.type == Task.TYPE_WRITE_WORD && [2,3].includes(task.difficulty));
            if (character.is_correct && highlightCorrectChar) {
                className += " color-red";
            }
            return (<div key={i} className={className} onClick={() => charClicked(character)}>{character.char}</div>);
        });
    }

    return (
        <div className="variable-letter">
            {charactersToChooseRender()}
        </div>
    );
}
