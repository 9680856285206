import {Action} from 'redux';
import {
    ADD_LEVEL_ACTION,
    ADD_LEVELS_ACTION,
    DROP_PROGRESS_ACTION,
    REMOVE_LEVEL_ACTION,
    UPDATE_LEVEL_ACTION
} from "./actions";
import {Level} from "../../models/level";
import {appStorage} from "../../services/AppStorage";

const INITIAL_STATE = {
    levels: [] as Level[],
};
type LevelsState = typeof INITIAL_STATE;

export const levelsReducer = (state: LevelsState = INITIAL_STATE, action: Action | any): LevelsState => {
    let newState = state;

    switch (action.type) {
        case ADD_LEVELS_ACTION:
            newState = addLevels(state, action.levels as Level[]);
            break;
        case DROP_PROGRESS_ACTION:
            newState = dropProgress(state, action.level as Level);
            break;
        case UPDATE_LEVEL_ACTION:
            newState = updateLevel(state, action.level as Level);
            break;
        case ADD_LEVEL_ACTION:
            newState = addLevel(state, action.level as Level);
            break;
        case REMOVE_LEVEL_ACTION:
            newState = removeLevel(state, action.level_id as number);
            break;

    }

    if (newState !== state) {
        newState = saveToStorage(sort(newState));
    }

    return newState;
};

function sort(state: LevelsState): LevelsState {
    return {
        ...state,
        levels: [
            ...state.levels,
        ].sort((a, b) => a.level_number > b.level_number ? 1 : -1),
    };
}

function saveToStorage(state: LevelsState): LevelsState {
    appStorage.setLevels(state.levels);
    return state;
}

function addLevels(state: LevelsState, levels: Level[]): LevelsState {
    return {
        ...state,
        levels: [
            ...state.levels.filter(l => !levels.find(l2 => l2.id == l.id)),
            ...levels,
        ]
    };
}

function dropProgress(state: LevelsState, level: Level): LevelsState {
    return state;
}

function updateLevel(state: LevelsState, level: Level): LevelsState {
    let stateLevel = state.levels.find(l => l.id == level.id);
    if (!stateLevel) {
        return state;
    }
    stateLevel = new Level({...stateLevel, ...level});
    return {
        ...state,
        levels: [
            ...state.levels.filter(l => l.id != level.id),
            stateLevel,
        ],
    }
}

function addLevel(state: LevelsState, level: Level) {
    let stateLevel = state.levels.find(l => l.id == level.id);
    if (!!stateLevel) {
        return state;
    }
    return {
        ...state,
        levels: [
            ...state.levels.filter(l => l.id != level.id),
            level,
        ],
    };
}

function removeLevel(state: LevelsState, level_id: number) {
    return {
        ...state,
        levels: [
            ...state.levels.filter(l => l.id != level_id),
        ]
    };
}
