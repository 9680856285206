import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {IonApp, IonRouterOutlet, isPlatform} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
//
// /* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
//
// /* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './main.css'
import InitialScreen from "./pages/InitialScreen";
import LevelsListScreen from "./pages/Child/LevelsListScreen";
import {createStore} from "redux";
import {appReducer} from "./state";
import {Provider} from 'react-redux';
import TasksListScreen from "./pages/Child/TasksListScreen";
import GameScreen from "./pages/Child/GameScreen";
import {BACKEND_URL} from "./constants";
import {Task} from "./models/task";
import {Level} from "./models/level";
import {appStorage, AppStorage} from "./services/AppStorage";
import {addLevels} from "./state/levels/actions";
import {addTasks, setTaskImgLocalPath} from "./state/tasks/actions";
import {Device, KeyboardResize, Plugins, StatusBarStyle} from "@capacitor/core";
import {FileTransfer} from "@ionic-native/file-transfer";
import {File} from "@ionic-native/file";
import {WebView} from "@ionic-native/ionic-webview";
import RewardScreen from "./pages/Child/RewardScreen";
import ParentLevelsListScreen from "./pages/Parent/Level/ParentLevelsListScreen";
import ParentLevelEditScreen from "./pages/Parent/Level/ParentLevelEditScreen";
import AboutScreen from "./pages/Parent/AboutScreen";
import TaskEditScreen from "./pages/Parent/Task/TaskEditScreen";
import TaskHelpScreen from "./pages/Parent/Task/TaskHelpScreen";
import SettingsScreen from "./pages/Parent/SettingsScreen";
import PictureSelectScreen from "./pages/Parent/Task/PictureSelectScreen";
import {setSoundsEnabled} from "./state/ui/actions";
import {SequencesPreload} from "./components/Sequences/Sequences";
import ImagesPreload from "./components/ImagesPreload/ImagesPreload";
import OnboardingScreen from "./pages/OnboardingScreen";

const {StatusBar, Keyboard} = Plugins;

StatusBar.setStyle({style: StatusBarStyle.Dark});
Keyboard.setAccessoryBarVisible({isVisible: false});
Keyboard.setResizeMode({mode: KeyboardResize.None});

/* Theme variables */
// import './theme/variables.css';

const store = createStore(
    appReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

async function loadBackendData() {
    const storage = new AppStorage();

    storage.getSettings().then(settings => {
        store.dispatch(setSoundsEnabled(settings.soundsEnabled));
    });

    if ((await storage.getTasks()).length && (await storage.getLevels()).length) {
        await storage.getTasks().then(tasks => store.dispatch(addTasks(tasks)));
        await storage.getLevels().then(levels => store.dispatch(addLevels(levels)));

        return;
    }

    const fetchUrl = BACKEND_URL + '/index.php?r=mobile-data%2Fmobile-data';
    const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'Device-UUID': Device.getUid(),
        },
    });

    const data: { tasks: Task[], levels: Level[] } = await response.json();
    const levels = data.levels.map(level => Level.fromJson(level));
    const tasks = data.tasks.map(task => Task.fromJson({...task, done: false}));

    store.dispatch(addLevels(levels));
    store.dispatch(addTasks(tasks));
}

export const saveLocalFile = async (tempFile: string) => {
    const tempFilename = tempFile.substr(tempFile.lastIndexOf('/') + 1);
    let tempBaseFilesystemPath = tempFile.substr(0, tempFile.lastIndexOf('/') + 1);
    let newBaseFilesystemPath = File.dataDirectory;
    if (isPlatform('android')) {
        if (!tempBaseFilesystemPath.match(/^\w+:\/\//)) {
            tempBaseFilesystemPath = 'file://' + tempBaseFilesystemPath;
        }
    }
    console.log({
        tempBaseFilesystemPath, tempFilename, newBaseFilesystemPath, tempFile,
    });
    let entry;
    try {
        entry = await File.resolveLocalFilesystemUrl(`${newBaseFilesystemPath}${tempFilename}`);
        console.log('exists', {entry});
    } catch(e) {
        console.log('does not exists');
        try {
            entry = await File.copyFile(tempBaseFilesystemPath, tempFilename, newBaseFilesystemPath, tempFilename);
        } catch (e) {
            console.error({
                e,
                tempFilename,
                newBaseFilesystemPath,
            });
            return '';
        }
    }
    return entry.toURL();
    // return newBaseFilesystemPath + tempFilename;
};

export const downloadTaskImage = async (task: Task) => {
    if (isPlatform("desktop")) {
        return;
    }

    let targetPath: string;
    let srcPath: string;
    if (task.img_external_path) {
        targetPath = task.img_external_path.replace(/http(s?):\/\//, '');
        srcPath = task.img_external_path;
    } else if (task.img_path) {
        targetPath = task.img_path;
        srcPath = BACKEND_URL + task.img_path;
    } else {
        return false;
    }
    const targetAbsPath = File.dataDirectory + targetPath;

    try {
        let url, entry;
        try {
            if (!await File.checkFile(File.dataDirectory, targetPath)) {
                throw new Error("File does not exists");
            }
            entry = await File.resolveLocalFilesystemUrl(targetAbsPath);
        } catch (e) {
            console.log('load', {targetAbsPath});
            entry = await FileTransfer.create().download(srcPath, targetAbsPath, false);
        }

        url = entry.toURL();
        store.dispatch(setTaskImgLocalPath(task.id!, url));
    } catch (e) {
        console.error({e})
    }
}

export function normalizeSrc(src: string) {
    if (typeof WebView.convertFileSrc === 'function') {
        return WebView.convertFileSrc(src);
    } else {
        return src;
    }
}

const downloadImages = async () => {

    const levels = (await appStorage.getLevels());
    const tasks = (await appStorage.getTasks());

    const sortedLevels = [...levels].sort((a,b) => a.level_number > b.level_number ? 1 : -1);
    for (let level of sortedLevels) {
        const filteredTasks = tasks
            .filter(t => t.level_id == level.id)
            .filter(t => !!t.img_path || !!t.img_external_path);
        for (const task of filteredTasks) {
            await downloadTaskImage(task);
        }
    }
}


loadBackendData().then(() => {
    downloadImages();
});


const App: React.FC = () => {
    return (
        <IonApp>
            <Provider store={store}>
                {/*<SequencesPreload/>*/}
                <ImagesPreload/>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Switch>
                            <Route path="/" component={InitialScreen} exact={true}/>
                            <Route path="/levels" component={LevelsListScreen} exact={true}/>
                            <Route path="/level/:level_id" component={TasksListScreen} exact={true}/>
                            <Route path="/level/:level_id/:operation" component={TasksListScreen} exact={true}/>
                            <Route path="/tasks/:task_id" component={GameScreen}/>
                            <Route path="/reward/:level_id" component={RewardScreen}/>

                            <Route path="/onboarding" component={OnboardingScreen}/>

                            <Route path="/parent/levels" component={ParentLevelsListScreen}/>
                            <Route path="/parent/photo/:task_id" component={PictureSelectScreen}/>
                            <Route path="/parent/level/:level_id" component={ParentLevelEditScreen}/>
                            <Route path="/parent/task/:task_id" component={TaskEditScreen}/>
                            <Route path="/parent/task_help/:task_id" component={TaskHelpScreen} exact={true}/>
                            <Route path="/parent/task_help" component={TaskHelpScreen} exact={true}/>
                            <Route path="/parent/settings" component={SettingsScreen}/>
                            <Route path="/parent/about" component={AboutScreen}/>

                            <Route render={() => <Redirect to="/"/>}/>
                        </Switch>
                    </IonRouterOutlet>
                </IonReactRouter>
            </Provider>
        </IonApp>
    );
}

export default App;
