import {File} from "@ionic-native/file";


export async function Base64Save(base64: string) {
    const mime = base64.replace(/data:(.+),/, '$1').trim();
    const format = mime.match('png') ? '.png' : '.jpg';
    const photoDir = 'Photos';
    
    let UUID = 'IMG-' + (new Date().getTime()).toString(16);
    let realData = base64.split(",")[1];
    let blob = b64toBlob(realData, mime);

    try {
        await File.checkDir(File.dataDirectory, photoDir);
        await File.writeFile(File.dataDirectory + `${photoDir}/`, UUID + format, blob);
    } catch(e) {
        await File.createDir(File.dataDirectory, photoDir, false);
        await File.writeFile(File.dataDirectory + `${photoDir}/`, UUID + format, blob);
    }

    return File.dataDirectory + `${photoDir}/` + UUID + format;
}


function b64toBlob(b64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}
