

export const PLAY_LEVEL_OPENED_SOUND_ACTION = 'LEVEL_OPENED_SOUND';
export const PLAY_LETS_WORK_SOUND_ACTION = 'LETS_WORK_SOUND';
export const PLAY_TASK_COMPLETED_SOUND_ACTION = 'TASK_COMPLETED_SOUND';
export const SET_SOUNDS_ENABLED_ACTION = 'SET_SOUNDS_ENABLED';



export const playLevelOpenedSound = () => ({
    type: PLAY_LEVEL_OPENED_SOUND_ACTION,
});

export const playLetsWorkSound = () => ({
    type: PLAY_LETS_WORK_SOUND_ACTION,
});

export const playTaskCompletedSound = () => ({
    type: PLAY_TASK_COMPLETED_SOUND_ACTION,
});

export const setSoundsEnabled = (enabled: boolean) => ({
    type: SET_SOUNDS_ENABLED_ACTION,
    enabled,
});
