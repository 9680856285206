import * as React from 'react';
import {IonContent, isPlatform} from "@ionic/react";
import {BasePageProps} from "../../../types";
import {Plugins} from "@capacitor/core";
import {LibraryItem, PhotoLibrary} from '@ionic-native/photo-library';
import {HTTP} from '@ionic-native/http';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateTask} from "../../../state/tasks/actions";
import {Task} from "../../../models/task";
import {downloadTaskImage, saveLocalFile} from "../../../App";
import {Camera} from "@ionic-native/camera";
import {Base64Save} from "../../../services/Base64Save";
import {WebView} from "@ionic-native/ionic-webview";

interface PictureSelectScreenProps extends BasePageProps<{ task_id: string }> {
    updateTask: (task: Task) => void,
    tasks: Task[],
}

interface PictureSelectScreenState {
    libraryImages: string[],
    depositImages: string[],
    shutterstockImages: string[],
    searchString: string,
}

class PictureSelectScreen extends React.Component<PictureSelectScreenProps, PictureSelectScreenState> {
    private searchTimeout?: NodeJS.Timeout;

    constructor(props: PictureSelectScreenProps) {
        super(props);
        this.state = {
            libraryImages: [],
            depositImages: [],
            shutterstockImages: [],
            searchString: '',
        };
    }

    private librarySubscription$: any;

    componentDidMount() {
        PhotoLibrary.requestAuthorization({read: true, write: false}).then(() => {
            if (isPlatform("ios")) {
                Plugins.Photos.getPhotos().then(value => {
                    this.setState(prev => ({
                        ...prev,
                        libraryImages: value.photos.map(photo => `data:image/png;base64, ${photo.data}`),
                    }));
                });
            } else if (isPlatform("android")) {
                this.librarySubscription$ = PhotoLibrary.getLibrary().subscribe((response: any) => {
                    const library: LibraryItem[] = response.library;
                    console.log({library});
                    this.setState(prev => ({
                        ...prev,
                        libraryImages: library.map((photo) => {
                            return WebView.convertFileSrc(photo.id.replace(/^\d+;(.*)$/, '$1'));
                        }),
                    }));
                });
            }
        });

        let task = this.props.tasks.find(t => t.id == Number(this.props.match.params.task_id));
        if (task && task.word && task.word.trim()) {
            this.setSearchString(task.word);
            this.searchImages(task.word);
        }
    }

    componentWillUnmount() {
        this.librarySubscription$?.unsubscribe();
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
    }

    renderImages(images: string[]) {
        return images.map((src, i) =>
            <div className="search-result__photo" key={i} onClick={() => this.chooseImage(src)}>
                <img src={src} alt=""/>
            </div>
        );
    }

    async request(url: string) {
        if (isPlatform("desktop")) {
            return await (await fetch(url)).text();
        } else if (isPlatform("ios") || isPlatform("android")) {
            return HTTP.get(url, {}, {}).then(response => response.data);
        }
    }

    async searchImages(searchString: string) {
        let stockPhotosSearchString = searchString.trim().toLowerCase().replace(' ', '-');
        stockPhotosSearchString = encodeURIComponent(stockPhotosSearchString);
        let response: any;

        let stockPhotosUrl = `https://ru.depositphotos.com/stock-photos/${stockPhotosSearchString}.html`;
        this.request(stockPhotosUrl)
            .then(data => {
                var el = document.createElement('html');
                el.innerHTML = data;
                (window as any).el = el;
                let imgTags = Array.from(el.getElementsByClassName('file-container__image')) as HTMLImageElement[];
                this.setState(prev => ({
                    ...prev,
                    depositImages: imgTags
                        .map(imgTag => imgTag.src || imgTag.dataset.src)
                        .filter(src => !!src) as string[],
                }));
            })
            .catch(err => {
                console.error({err});
            });

        let shutterStockSearchString = searchString.trim().toLowerCase().replace(' ', '+');
        shutterStockSearchString = encodeURIComponent(shutterStockSearchString);

        let shutterStockUrl = `https://www.shutterstock.com/ru/search/${shutterStockSearchString}`;
        this.request(shutterStockUrl)
            .then(data => {
                let matches = data.matchAll(/"contentUrl":"([^"]*)"/g);
                let images = [] as string[];
                do {
                    let next = matches.next();
                    if (next.done) break;
                    images.push(next.value[1]);
                } while (true);

                this.setState(prev => ({
                    ...prev,
                    shutterstockImages: images,
                }));
            })
            .catch(err => {
                console.error({err});
            });
    }

    goBack() {
        this.props.history.replace(`/parent/task/${this.props.match.params.task_id}`);
    }

    capturePhotoFromCamera() {
        let task = this.props.tasks.find(t => t.id == Number(this.props.match.params.task_id));
        if (!task) {
            this.props.history.replace(`/parent/levels`);
        }
        Camera.getPicture({
            mediaType: Camera.MediaType.PICTURE,
            sourceType: Camera.PictureSourceType.CAMERA,
            allowEdit: false,
        }).then(filePath => {
            console.log({'Photo Result': filePath});
            saveLocalFile(filePath).then(savedPath => {
                let newTask = new Task({...task, img_local_path: savedPath});
                this.props.updateTask(newTask);
            });
            this.props.history.replace(`/parent/task/${this.props.match.params.task_id}`);
        });
    }

    pickPhoto() {
        let task = this.props.tasks.find(t => t.id == Number(this.props.match.params.task_id));
        if (!task) {
            this.props.history.replace(`/parent/levels`);
        }
        Camera.getPicture({
            mediaType: Camera.MediaType.PICTURE,
            sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
            allowEdit: false,
        }).then(filePath => {
            console.log({'Photo Result': filePath});
            saveLocalFile(filePath).then(savedPath => {
                let newTask = new Task({...task, img_local_path: savedPath});
                this.props.updateTask(newTask);
            });
            this.props.history.replace(`/parent/task/${this.props.match.params.task_id}`);
        });
    }

    render() {
        return (
            <IonContent className="parent">
                <div className="wrapper">
                    <div className="header">
                        <div className="container">
                            <a className="btn-back" onClick={() => this.goBack()}>
                                <span>
                                    <img src="/assets/images/arrow-left.svg" alt=""/>
                                </span>
                            </a>
                            <div className="form form-search">
                                <label>
                                    <input type="text"
                                           value={this.state.searchString}
                                           onInput={e => this.updateSearchString((e.target as any).value)}
                                           placeholder="Поиск" name="search"/>
                                </label>
                                <button className="btn-clear" type="button"><img src="/assets/images/clear.svg" alt=""/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="content content-page-search">
                        <div className="container">
                            <div className="search-result">
                                <div className="search-result-content">
                                    <h4>Галерея устройства</h4>
                                    <div className="search-result__wrapper">
                                        <div className="search-result__photo search-result__photo-camera" onClick={() => this.capturePhotoFromCamera()}>
                                            <img src="/assets/images/camera.svg" />
                                        </div>
                                        <div className="search-result__photo search-result__photo-more" onClick={() => this.pickPhoto()}>
                                            <img src="/assets/images/more-vert.svg" />
                                            <img src="/assets/images/more-vert.svg" />
                                            <img src="/assets/images/more-vert.svg" />
                                        </div>
                                        {this.renderImages(this.state.libraryImages)}
                                    </div>
                                </div>

                                <div className="search-result-content">
                                    <h4>Depositphotos</h4>
                                    <div className="search-result__wrapper">
                                        {this.renderImages(this.state.depositImages)}
                                    </div>
                                </div>
                                <div className="search-result-content">
                                    <h4>Shutterstock</h4>
                                    <div className="search-result__wrapper">
                                        {this.renderImages(this.state.shutterstockImages)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        );
    }

    private setSearchString(searchString: string) {
        this.setState(prev => ({...prev, searchString}));
    }

    private updateSearchString(searchString: string) {
        this.setSearchString(searchString);
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            this.searchImages(searchString);

            clearTimeout(this.searchTimeout!);
            this.searchTimeout = undefined;
        }, 1000);
    }

    private chooseImage(src: string) {
        let task = this.props.tasks.find(t => t.id == Number(this.props.match.params.task_id));
        if (task) {
            if (src.match(/^http/)) {
                let newTask = new Task({...task, img_external_path: src, img_local_path: ''});
                this.props.updateTask(newTask);
                downloadTaskImage(newTask).then(() => {
                    this.props.history.replace(`/parent/task/${this.props.match.params.task_id}`);
                });
            } else {
                if (src.match(/data:image\/\w+;base64/)) {
                    Base64Save(src).then(saved => {
                        let newTask = new Task({...task, img_external_path: '', img_local_path: saved});
                        this.props.updateTask(newTask);
                        this.props.history.replace(`/parent/task/${this.props.match.params.task_id}`);
                    });
                } else {
                    saveLocalFile(src).then(saved => {
                        let newTask = new Task({...task, img_external_path: src, img_local_path: saved});
                        this.props.updateTask(newTask);
                        this.props.history.replace(`/parent/task/${this.props.match.params.task_id}`);
                    });
                }
            }
        } else {
            this.props.history.replace(`/parent/levels`);
        }
    }
}

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        updateTask,
    }, dispatch)
);
const mapStateToProps = (state: any) => {
    const {tasks,} = state
    return {tasks: tasks.tasks, };
};
export default connect(mapStateToProps, mapDispatchToProps)(PictureSelectScreen);
