
export type CardCompletionSetting = 'byOrder' | 'notCompleted';

export class Settings {
    cardCompletion: CardCompletionSetting = 'notCompleted';
    askPassword: boolean = true;
    parentPassword: string = '';
    soundsEnabled: boolean = true;
    onboarded: boolean = false;
}
