import * as React from "react";
import {Task} from "../../models/task";
import {GameState} from "./types";
import {GameComponentState} from "./GameComponent";
import {SadFaceSequence} from "../Sequences/Sequences";

export interface GameCardComponentProps {
    task: Task;
    gameComponentState: GameComponentState;
    onClickSuccess: Function;
    inputWord: string;
}

export default function GameCardComponent({task, gameComponentState, onClickSuccess, inputWord}: GameCardComponentProps) {
    const correctWordRender = () => {
        if (task.type == Task.TYPE_WRITE_WORD || task.type == Task.TYPE_FIND_CHAR) {
            return null;
        }

        const wordArray = task.word.split('');
        const wordWithHiddenArray = task.wordWithHiddenChars().split('');
        const renderCharacters = () => wordArray.map((char, i) => {
            const isMissing = wordWithHiddenArray[i] == Task.HIDDEN_CHAR;
            const highlightCorrectChar =
                (task.type == Task.TYPE_FIND_SAME_CHAR && task.difficulty < 3) ||
                (task.type == Task.TYPE_FIND_CHAR && task.difficulty == 1);

            let className = "card-product-word__box";
            if (isMissing && highlightCorrectChar) {
                className += " color-red";
            }
            return (<div className={className} key={i}>{char}</div>);
        })
        return (
            <div className="row">
                {renderCharacters()}
            </div>
        );
    }

    const inputWordRender = () => {
        if (gameComponentState.gameState == 'success') {
            return null;
        } else if (gameComponentState.gameState == 'fail') {
            return null;
        }

        const renderCharacters = () => {
            let chars;
            if (task.type != Task.TYPE_WRITE_WORD) {
                chars = task.wordWithHiddenChars();
            } else {
                chars = task.word.split('').map((char, i) => inputWord[i] || Task.HIDDEN_CHAR).join('');
            }
            return chars.split('').map((char, i) => {
                const isHidden = char == Task.HIDDEN_CHAR;
                return <div className="card-product-word__box" key={i}>{isHidden ? null : char}</div>;
            });
        }
        return (
            <div className="row">
                {renderCharacters()}
            </div>
        );
    }

    const successWordRender = () => {
        const wordArray = task.word.split('');
        const renderCharacters = () => wordArray.map((char, i) => {
            let className = "card-product-word__box";
            return (<div className={className} key={i}>{char}</div>);
        });
        return renderCharacters();
    };

    const wordsRender = () => {
        if (gameComponentState.gameState == 'success') {
            return (
                <div className="ready-word">
                    {successWordRender()}
                </div>
            );
        }

        return (
            <div className="card-product-word">
                {correctWordRender()}
                {inputWordRender()}
            </div>
        );
    }

    return (
        <div className="card-product">
            <div className="card-product__box">
                <div className="card-product-img">
                    <img src={task.imageSrc} alt=""/>
                </div>
                <Overlay state={gameComponentState.gameState} onClickSuccess={() => onClickSuccess()}>
                    {wordsRender()}
                </Overlay>
            </div>
        </div>
    );
}


type OverlayProps = {
    children: any,
    state: GameState,
    onClickSuccess: Function,
};
function Overlay(props: OverlayProps) {
    if (props.state == 'default') {
        return (props.children);
    }

    switch (props.state) {
        case 'fail':
            return (
                <div className="card-product__box-overlay">
                    <div className="card-product__box-star">
                        <SadFaceSequence onDone={() => {}} />
                    </div>
                    {props.children}
                </div>
            );
        case 'success':
            return (
                <div className="card-product__box-overlay" onClick={() => props.onClickSuccess()}>
                    <div className="card-product__box-star">
                        <div className="star-max-wrapper">
                            <img src={"/assets/images/star-max.svg"} alt=""/>
                        </div>
                    </div>
                    {props.children}
                </div>
            );
        default:
            return props.children;
    }
}
